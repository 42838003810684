
import React from 'react';
import { Slider, Badge, Button, Grid, Box, Divider, Stack } from '@mui/material';
export default function DatePicker({ dates, times, onChange, selectedDate, ...props }) {
    let seltime = times?.length>0?times[0]:null
    const [selTime, setSelTime] = React.useState(seltime)
    const [selDate, setSelDate] = React.useState(selectedDate)
    return <Stack direction={'row'} spacing={1} sx={{ p: 1 }}>
        <Box flexItem sx={{ background: '#EDC66B', p: '2px', borderRadius: 2 }}>
            <Box sx={{ textAlign: 'center' }}>Date</Box>
            <Box sx={{ overflowY: 'auto', maxHeight: 100, p: 1 }}>
                {dates?.map((date, i) => <Box sx={{ color: (date == selectedDate ? "#3ba3d5" : "") }}
                    onClick={() => { setSelDate(date); onChange("dates", date) }} key={i}>{date}</Box>)}
            </Box>
        </Box>
        <Box flexItem sx={{ background: '#edc66bc9', p: '2px', borderRadius: 2 }}>
            <Box sx={{ textAlign: 'center' }}>Time</Box>
            <Box sx={{ overflowY: 'auto', maxHeight: 100, p: 1 }}>
                {times?.map((time, i) => <Box sx={{ color: (time == selTime ? "#3ba3d5" : "")}}
                    onClick={() => { setSelTime(time); onChange("times", time, selDate) }} key={i}>{time.split(".")[0]}</Box>)}
            </Box>
        </Box>
    </Stack>
}