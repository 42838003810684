import * as React from "react";
import { Input, ViewSave } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Pricing from "./Pricing";


export default function Package(props) {
    const [more, setMore] = React.useState({});
    let packageState = useSelector((state) => state.package?.data)
    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch({ type: REQUESTS.PACKAGE.GET.ACTION, payload: 0 })
    }, [])

    const onSubmit = (e, data) => {
        let action = data.id ? REQUESTS.PACKAGE.PATCH.ACTION : REQUESTS.PACKAGE.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.PACKAGE.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.PACKAGE.GET.ACTION, payload: 0 })
            }
        })
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <ViewSave dataList={packageState?.results}
                    onSubmit={onSubmit}
                    renderSearch={() => <></>}
                    height={'84vh'}
                    onDelete={onDelete}
                    // title={"Package"}
                    moreIcon={<Box alignContent={'center'} sx={customCss.Btn.Warn}><MoreHorizOutlinedIcon sx={{ fontSize: customCss.Btn.Warn.fontSize }} /></Box>}
                    onMore={(data) => {
                        setMore(data)
                    }}
                    body={<Grid container spacing={4} />}
                    renderRow={(data, { index, setFormData, setShowModal }) => {
                        return <><Pricing
                            data={data}
                            index={index}
                            setFormData={setFormData}
                            setShowModal={setShowModal} />
                            {(index === 3 || (packageState?.results.length - 1 === index && index !== 3)) && <Pricing
                                data={data}
                                editable={true}
                                index={index}
                                setFormData={setFormData}
                                setShowModal={setShowModal} />}
                        </>
                    }}
                    hideInfo
                >
                    {/* <Input type={"image"} label={"Avatar"} name={"image"} required={false} /> */}
                    <Input label={"Amount"} name={"amount"} required={true} />
                    <Input label={"No of days"} name={"days"} required={true} />
                    <Input label={"image_rentention"} name={"image_rentention"} required={true} />
                    <Input label={"video_rentention"} name={"video_rentention"} required={true} />
                    <Input label={"no_of_camera"} name={"no_of_camera"} required={true} />
                    <Input type="textarea" label={"Description"} name={"description"} required={true} />
                </ViewSave>
            </Grid >
            {/* <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid> */}
        </Grid >
    </>
}