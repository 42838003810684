import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import XSideBar from './XSideBar';
import { Routes, Route } from "react-router-dom";
import { menuItems, HomePage } from '../content/menus';
import Login from '../pages/auth/Login';
import ZToolbar from '../layout/ZToolbar';
import Error404 from '../pages/error/Error404';
import { css } from '../content/Style'
import useMediaQuery from '@mui/material/useMediaQuery';
const drawerWidth = 40;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 12,
    boxShadow: 'none',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  ...css.AppBar,
  borderLeft: 0,
  borderTop: 0,
  borderRight: 0,
  boxShadow: 'none',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    ...css.AppBar,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Layout(props) {
  let subMenus = []
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = React.useState(matches);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open && props.isLogged} >
        <ZToolbar handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} title={props?.name} customCss={css} />
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            ...css.SideBar,
            height: '100vh',
            overflowX: 'hidden',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open && props.isLogged}
      >
        <div style={{ padding: 3, borderTop: 0, borderRight: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column',justifyContent:"center", alignItems: 'center', ...css.SideBar?.header }}>

            {css.SideBar?.header?.icon ? <img style={css.SideBar?.header?.icon} src={css.SideBar?.header?.src} /> :
              <span>{props?.name}</span>}
            
          </Box>
          <Divider />
        </div>
        {props.isLogged && <XSideBar menuItems={menuItems} hideLabel={false} />}
      </Drawer>
      <Main open={open && props.isLogged} style={{ maxHeight: '98vh', overflow: 'auto' }}>
        <div style={{ height: 40 }}></div>
        <Routes>
          <Route exact={"true"} path={"/"} element={<HomePage />}></Route>
          <Route exact={"true"} path={"/login"} element={<Login />}></Route>
          {
            menuItems.map((menu, i) => {
              if (menu.subMenus) {
                subMenus = subMenus.concat(menu.subMenus)
              }
              return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
            })
          }
          {
            subMenus.map((menu, i) => {
              return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
            })
          }
          <Route path={"*"} element={<Error404 />}></Route>
        </Routes>
      </Main>
    </Box>
  );
}