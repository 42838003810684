const bgColor = 'white'
const border = '0px solid #d9e3f0'
const color = 'black'

const RoundBtn = {
    width: 22,
    height: 22,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const css = {
    AppBar: {
        backgroundColor: bgColor,
        border: border,
        height: 40,
        color: '#1F2F3E',
        Chip: {
            color: '#1F2F3E',
        }
    },
    SideBar: {
        backgroundColor: '#373d42',
        border: 'none',
        color: 'red',
        iconColor: bgColor,
        iconSize: '16px',
        header: {
            backgroundColor: '#373d42',
            height: 100,
            color: '#ffb100',
            src: "https://bglive.co/bglive/assets/images/logo.png",
        }
    },
    Card: {
        header: {
            backgroundColor: bgColor,
            border: border,
            color: '#15202b',
            borderRadius: '6px 6px 0 0',
            opacity: 0.6,
        },
        body: {
            border: 0,
            backgroundColor: 'white',
            boxShadow: 'none',
        },
        footer: {

        },
    },
    List: {
        Frame: {
            backgroundColor: 'white',
            padding: 1,
            borderRadius: 2,
            textTransform: 'capitalize',
        },
        Title: {
            py: 0,
            textTransform: 'capitalize',
        },
        Col: {
            fontSize: 12,
            textTransform: 'capitalize',
        },
        Row: {
            fontSize: 12,
            textTransform: 'capitalize',
        },
        ActionCol: {
            minWidth: 110,
        },
        ColFirst: {
            maxWidth: 50,
        }

    },
    Table: {
        header: {
            bacnkgroundColor: bgColor,
            border: border,
            color: color
        },
    },
    Btn: {
        Edit: {
            fontSize: 12,
            color: '#299de1',
            backgroundColor: '#33bfbf1f',
            ...RoundBtn
        },
        Delete: {
            fontSize: 12,
            color: '#ff6150',
            mx: 1,
            backgroundColor: '#ff61501f',

            ...RoundBtn,

        },
        Warn: {
            fontSize: 10,
            color: '#ffbc00',
            backgroundColor: '#f8aa4b1f',
            ...RoundBtn,
            cursor: 'pointer',
        },
        Success: {
            fontSize: 12,
            color: '#02b2af'
        }
    },
    Content: {
        small: {
            fontSize: 12,
            color: 'gray'
        },
        medium: {
            fontSize: 13,
            color: 'gray'
        }
    },
    Round: {
        width: '46px',
        height: '46px',
        borderRadius: '100%',
        display: '-webkit-box',
        display: '-ms-flexbox',
        display: 'flex',
        fontSize: '25px',
        fontWeight: 600,
        webkitBoxAlign: 'center',
        msFlexAlign: 'center',
        alignItems: 'center',
        webkitBoxPack: 'center',
        msFlexPack: 'center',
        justifyContent: 'center',
        backgroundColor: '#35bfbf',
        marginRight: '10px',
        color: '#35bfbf',
        backgroundColor: 'rgba(51, 191, 191, 0.12) !important'
    },
    // this is also used for dashbord cards
    Colors: [
        { color: '#02b2af', bg: '#33bfbf1f' },
        { color: "#7c7c7c", bg: '#15202b1f' },
        { color: "#ff6150", bg: '#ff61501f' },
        { color: "#f8aa4b", bg: '#f8aa4b1f' },
        { color: "#1fd8a4", bg: '#f8aa4b1f' },//green inflade
        { color: "#ffc20e", bg: '#f8aa4b1f' },//bglive primary
    ],
    Form: {
        border: '1px solid #dee2e6',
        padding: 2,
        borderRadius: 2
    },
    MenuBar: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        bgcolor: 'background.paper',
        color: 'text.secondary',
        '& svg': {
            m: 1,
        },
        '& hr': {
            mx: 0.5,
        },
        width: '100%'
    },

}