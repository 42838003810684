
import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, Badge, Button, Grid, Box, Divider } from '@mui/material';
import { REQUESTS } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { getOrientation } from 'get-orientation/browser';
import getCroppedImg, { getOrginalImage } from "../../libs/imgcrop/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { css as customCss } from '../../content/Style'
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import ImageViewer from '../image/ImageViewer'
import SubMenus from '../image/SubMenus';
import './main.css'
import { CallToActionSharp } from '@mui/icons-material';
const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

// http://<server>/mjpg/video.mjpg video api
// http://<server>/mjpg/video.mjpg video api

const LivePreview = ({ data }, props) => {
    let companyState = useSelector((state) => state.livecam?.data)
    let dispatch = useDispatch()
    let location = useLocation()
    let navigate = useNavigate();
    const aspect = 4 / 3
    let src = "https://bglive.atpro.in/media/camera/timelapse/OIP.jpg"
    src = "http://plotdlive.bglivepro.com/mjpg/video.mjpg"
    const [imageSrc, setImageSrc] = React.useState(src)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [height, setHeight] = useState(0)
    const [image, setImage] = useState(null)
    let cropperRef = React.createRef()

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            cropperRef.current.cropper.getCroppedCanvas({
                fillColor: '#fff',
                imageSmoothingEnabled: false,
                imageSmoothingQuality: 'high',
            }).toBlob((blob) => {
                console.error(blob)
                setCroppedImage(blob);

            })
            // const croppedImage = await getCroppedImg(
            //     imageSrc,
            //     croppedAreaPixels,
            //     rotation
            // );

        } catch (e) {
            console.error(e);
        }

    }, [imageSrc, croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, [])
    const onMediaLoad = (mediaSize) => {
        setHeight(mediaSize.height - 10);
    };
    const pan = () => {

    }
    const createUrl = (data) => data.protocol + data.ip_address + data.http_port + data.ip_url
    const request = async (data, value) => {
        let url = data.ptz_url.replace("$", value)
        dispatch({
            type: REQUESTS.PTZACTION.SEARCH.ACTION, payload: `ptzaction/${data.id}/${value}/`, callback: (data) => {
                console.log("RESPONSE", data)
                if (value === "image") {
                    navigate("/imageviewer/", {
                        state: {data:location.state, image:data.data, back:"/livepreview/"}
                    })
                }
            }
        })
    }

    return (<>
        {Boolean(image) ? <ImageViewer data={data} image={image} onClose={()=>{setImage(null)}}/> : <div>
            <div className={"controls"}>

                <Grid container>
                    <Grid sm="12" md="12">
                        <Box sx={customCss.MenuBar}>
                            <SubMenus icon={<SettingsOutlined />}>
                                {location.state?.actions.map((action) => action.action == 'System' &&

                                    <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                        <Badge
                                            variant="overline"
                                        >
                                            <RestartAltOutlinedIcon onClick={(e)=>{ request(action, null)}}/> {action.label}
                                        </Badge>
                                        
                                    </Box>
                                )}
                            </SubMenus>
                            <Divider orientation="vertical" flexItem />
                            <SubMenus icon={<TuneOutlinedIcon />}>
                                {location.state?.actions.map((action) => action.action == 'PTZ' &&

                                    <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                        <Badge
                                            variant="overline"
                                        >
                                            {action.label} {action.min_value} - {action.max_value}
                                        </Badge>
                                        <Slider
                                            shiftStep={5}
                                            step={(action.max_value / 100)}
                                            marks={[{
                                                value: action.min_value,
                                                label: '',
                                            }, {
                                                value: action.max_value,
                                                label: '',
                                            }]}
                                            min={action.min_value}
                                            defaultValue={1}
                                            max={action.max_value}
                                            valueLabelDisplay="auto"
                                            onChange={(e) => request(action, e.target.value)}
                                            sx={{ width: '100%' }} />
                                    </Box>
                                )}
                            </SubMenus>
                            <Divider orientation="vertical" flexItem />
                            <SubMenus icon={<PhotoCameraIcon />}>
                                {location.state?.actions.map((action) => action.action == 'Image' &&
                                    <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                        <Badge
                                            variant="overline"
                                        >
                                            {action.label}
                                        </Badge>
                                        <div>
                                            <PhotoCameraIcon
                                                onClick={(e) => request(action, "image")} />
                                        </div>
                                    </Box>
                                )}
                            </SubMenus>
                            <Divider orientation="vertical" flexItem />
                            <FormatPaintOutlinedIcon />
                            <Divider orientation="vertical" flexItem />
                            <PsychologyOutlinedIcon />
                            <Divider orientation="vertical" flexItem />
                            <SubMenus icon={<PsychologyOutlinedIcon />}>
                                <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                    <Badge
                                        variant="overline"
                                    >
                                        Zoom
                                    </Badge>
                                    <Slider
                                        shiftStep={5}
                                        step={1}
                                        marks
                                        min={1}
                                        defaultValue={1}
                                        max={8}
                                        valueLabelDisplay="auto"
                                        onChange={(e) => setZoom(e.target.value)}
                                        sx={{ width: '100%' }} />
                                </Box>
                                <Box flex alignItems={'center'} sx={{ px: 2 }}>
                                    <Badge
                                        variant="overline"
                                    >
                                        Rotation
                                    </Badge>
                                    <Slider
                                        shiftStep={10}
                                        step={10}
                                        marks
                                        min={0}
                                        defaultValue={1}
                                        max={180}
                                        onChange={(e) => setRotation(e.target.value)}
                                    />
                                </Box>
                            </SubMenus>
                            <Divider orientation="vertical" flexItem />
                        </Box>

                    </Grid>
                </Grid>
            </div>
            <Box height={8}></Box>
            <Box className={'cambg'} position={'relative'} zIndex={10} height={'78vh'}>
                <div className={'crop-container'}>
                    <Cropper
                        image={createUrl(location.state)}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        cropShape={"rectangle"}
                        maxZoom={8}
                    />
                </div>


            </Box></div>}
    </>
    )
}
export default LivePreview
