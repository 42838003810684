import * as React from "react";
import { Input, XForm } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Badge, Box, Button, Divider, Grid, Typography } from "@mui/material";
import { css as customCss } from '../../content/Style'
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ZModal from "../../libs/ZModal";


export default function Pricing({ data, index, setFormData, setShowModal, onSubmit, editable }) {
    const [more, setMore] = React.useState({});
    const [show, setShow] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState({ type: "success", text: 'Enter your email' });
    const [otpValid, setOtpValid] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [initOtp, setInitOtp] = React.useState('');
    const [title, setTitle] = React.useState("Verify Your email ID");
    const [form, setForm] = React.useState({});
    const [visform, setVisform] = React.useState({ email: true, code: false, phone: false });

    let dispatch = useDispatch()
    const inputcss = {
        width: 60,
        height: 20,
        background: '#f5f5f5',
        border: 'none',
        marginBottom: '4px',
        paddingLeft: '10px'
    }
    const onSubmite = (e, data) => {
        setInitOtp(Math.random().toString().concat("0".repeat(3)).substr(2, 3))
        console.log("DATA OTP " + initOtp)
        console.log('DATA', data)
        setMessage({ type: "", text: 'Enter your email' })
        setTitle("Verify Your email ID");
        setVisform({ email: true, code: false, phone: false })
        setForm(data)
        setShow(true)

    }
    return <>
        <ZModal showModal={show}
            title={title}
            onAction={(action) => {
                if (action === 'Save') {
                    if (visform.email) {
                        //send mail server code
                        console.log("DATA OTP " + initOtp)
                        setVisform({ email: false, code: true, phone: false })
                        setTitle("Enter code")
                        setMessage({ type: "success", text: `OTP has been sent your ${email} account` })
                        dispatch({
                            type: REQUESTS.MAIL.POST.ACTION,
                            payload: {
                                email: email,
                                otp: initOtp,
                                action: "otp"
                            }
                        })
                    } else if (visform.code) {
                        //send mail server code
                        setVisform({ email: false, code: otp !== initOtp, phone: otp == initOtp })
                        if (otp == initOtp) setTitle("Enter phone number")
                        if (otp !== initOtp) setMessage({ type: "error", text: `Please enter valid OTP` })

                    } else if (visform.phone) {
                        //send save package server code

                    }

                } else {
                    console.log("ACTION", action)
                    setShow(false)
                }
            }
            }
            successBtn="Verify"
        >
            <Box sx={{ py: 2, ...customCss.Form }} >
                <Box >
                    <Typography sx={{ fontSize: 12, my: 2 }} className={message.type}>{message.text}</Typography>
                </Box>
                {visform.email && <Input name="email" value={email} onChange={(e) => {
                    setEmail(e.target.value)
                    e.target.focus()
                }} />}
                {visform.code && <Input name="code" value={otp} onChange={(e) => {
                    setOtp(e.target.value)
                    console.log("valid:", e.target.value == initOtp, e.target.value, initOtp)
                }} />}
                {visform.phone && <Input name="phone" />}
                {otpValid && <CheckCircleIcon />}
            </Box>

        </ZModal>

        <Grid item md={3}>
            <Box sx={{ ...customCss.Form, textAlign: 'center' }}>
                <XForm onSubmit={onSubmite}>
                    <Typography><ViewInArOutlinedIcon /></Typography>
                    <input name="id" type={"hidden"} value={data.id} />
                    <Typography> {editable ? <>Custom <input type="hidden" name="title" value="Custom" /></> : data.title}</Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        {editable ? <>
                            Create your own package and send a quote we will reach out you
                            <input type="hidden"
                                name="description"
                                value="Create your own package and send a quote we will reach out you" />
                        </> : data.description}
                    </Typography>
                    {!editable ? <Typography
                        sx={{ my: 1, fontSize: 32, fontWeight: 800 }}
                        component={'h1'}>{data.amount} $ </Typography> : null}
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ fontSize: 12, pb: 1 }}>Storage</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /> <Typography sx={{ fontSize: 12 }}>Image Rentention {editable ? <input style={inputcss} name="image_rentention" defaultValue={data.image_rentention} /> : data.image_rentention} Days</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /> <Typography sx={{ fontSize: 12 }}>Video Rentention {editable ? <input style={inputcss} name="video_rentention" defaultValue={data.video_rentention} /> : data.video_rentention} Days</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', }}>
                        <Typography sx={{ fontSize: 12, pt: 2, pb: 1 }}>Construction Camera (Live)</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /><Typography sx={{ fontSize: 12 }}>HD - PTZ - Box {editable ? <input style={inputcss} name="hd_ptz" defaultValue={data.hd_ptz} /> : data.hd_ptz} Nos</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /><Typography sx={{ fontSize: 12 }}>4K - PTZ - Box {editable ? <input style={inputcss} name="_4K_ptz" defaultValue={data._4K_ptz} /> : data._4K_ptz} Nos</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', }}>
                        <Typography sx={{ fontSize: 12, pt: 2, pb: 1 }}>Timelapse Camera</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /><Typography sx={{ fontSize: 12 }}>24 MP {editable ? <input style={inputcss} name="_24_ptz" defaultValue={data._24_ptz} /> : data._24_ptz} Nos</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /><Typography sx={{ fontSize: 12 }}>32 MP {editable ? <input style={inputcss} name="_32_ptz" defaultValue={data._32_ptz} /> : data._32_ptz} Nos</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /><Typography sx={{ fontSize: 12 }}>50 MP  {editable ? <input style={inputcss} name="_50_ptz" defaultValue={data._50_ptz} /> : data._50_ptz} Nos</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} /><Typography sx={{ fontSize: 12 }}>60 MP  {editable ? <input style={inputcss} name="_60_ptz" defaultValue={data.hybrid} /> : data._60_ptz} Nos</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', pt: 2, pb: 1 }}>
                        <CheckCircleIcon fontSize="8" sx={{ mx: 1 }} />
                        <Box>
                            <Typography sx={{ fontSize: 12 }}>Live HD Streaming +</Typography>
                            <Typography sx={{ fontSize: 12 }}>HD Timelapse</Typography>
                        </Box>
                        <Typography sx={{ fontSize: 12 }}>{editable ? <input style={inputcss} name="hybrid" defaultValue={data.hybrid} /> : data.hybrid} Nos</Typography>
                    </Box>
                    <Button type={"submit"} variant="primary">{editable ? "Send Quaote" : "Buy Plan"}</Button>
                </XForm>
            </Box>
        </Grid>
    </>
}