import * as React from "react";
import { Input, ViewSave, makeQuery, makeSearchQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';


export default function Requests(props) {
    const [more, setMore] = React.useState({});
    let packageState = useSelector((state) => state.package?.data)
    let dispatch = useDispatch()

    React.useEffect(() => {
        dispatch({ type: REQUESTS.PACKAGE.GET.ACTION, payload: 0 })
    }, [])

    const onSubmit = (e, data) => {
        let action = data.id ? REQUESTS.PACKAGE.PATCH.ACTION : REQUESTS.PACKAGE.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.PACKAGE.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.PACKAGE.GET.ACTION, payload: 0 })
            }
        })
    }
    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.PACKAGE.GET.ACTION, payload: `package/0/?filter_by=${query}` })
    }
    const onClickSearch = (value) => {
        let query = makeSearchQuery(['description', 'title', 'days'], value)
        dispatch({ type: REQUESTS.PACKAGE.GET.ACTION, payload: `package/0/?search_by=${query}` })
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <ViewSave
                    dataList={packageState?.results}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    onClickFilter={onClickFilter}
                    onClickSearch={onClickSearch}
                    // renderSearch={() => <></>}
                    height={'90vh'}
                    title={"Package Requests"}
                    // moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 18 }} />}
                    row={<TableRow></TableRow>}
                    col={<TableCell></TableCell>}
                    head={<th style={{ width: 100 }}></th>}
                    cover={<Table></Table>}
                    body={<TableBody></TableBody>}
                    showColumns={['title']}
                    // customIcon={<CheckCircleIcon />}
                    onMore={(data) => { setMore(data) }}
                    showDwnload={true}
                    hideEditBtn
                    hideDelBtn
                    showHeader
                    noActionCol
                    headerList={[{ key: 'index', style: { width: 70 }, label: 'SL' },
                        'description', 'title', { key: 'days', label: "Days", type: 'number' }]}
                >
                    {/* <Input type={"image"} label={"Avatar"} name={"image"} required={false} /> */}
                    <Input label={"Title"} name={"title"} required={true} />
                    <Input type="textarea" label={"Description"} name={"description"} required={true} />
                    <Input label={"Amount"} name={"amount"} required={true} />
                    <Input label={"No of days"} name={"days"} required={true} />
                    <Input label={"Image Rentention"} name={"image_rentention"} required={true} />
                    <Input label={"Video Rentention"} name={"video_rentention"} required={true} />
                    <hr />
                    <Input label={"HD No of Camera"} name={"hd_ptz"} required={true} />
                    <Input label={"4K No of Camera"} name={"_4k_ptz"} required={true} />
                    <hr />
                    <Input label={"24 MP No of Camera"} name={"_24mp"} required={true} />
                    <Input label={"32 MP No of Camera"} name={"_32mp"} required={true} />
                    <Input label={"50 MP No of Camera"} name={"_50mp"} required={true} />
                    <Input label={"60 MP No of Camera"} name={"_60mp"} required={true} />
                    <hr style={{ marginBottom: 20 }} />
                    <Input label={"Live + Timelapse"} name={"hybrid"} required={true} />
                </ViewSave>
            </Grid >
        </Grid >
    </>
}