import * as React from "react";
import { CImg, Input, XForm, K } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Grid, Typography } from "@mui/material";

export default function Availability({ db_plan }) {
    return <>
        <Box sx={{ flexGrow: 1, mt: 1 }} style={db_plan?.Availability?.style?.box}>

            {db_plan?.Availability?.json?.map((av) =>
                <Grid item md={3}>
                    <Box style={db_plan?.Availability?.style?.Card}>
                        <Typography style={db_plan?.Availability?.style?.List}>{K(av.name, "db_plan?.Availability?.text.name")}</Typography>
                        <Typography style={db_plan?.Availability?.style?.List}>{K(av.time, "db_plan?.Availability?.text.time")}</Typography>
                        <Typography style={db_plan?.Availability?.style?.List}>{K(av.phone, "db_plan?.Availability?.text.phone")}</Typography>
                    </Box>
                </Grid>
            )}
        </Box>
    </>
}