import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Save } from "@mui/icons-material";

function ZButton({
  title,
  loading,
  handleButtonClick,
  rendericon,
  width,
  fullWidth,
  bgcolor,
  type, color, disablingCondition, sx
}) {
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        disabled={loading || disablingCondition}
        sx={sx}
        fullWidth={fullWidth}
        type={type}

      >
        {loading && <CircularProgress size={24} color="inherit" />}
        {loading && rendericon && (rendericon ? rendericon : <Save />)}
        {rendericon && !loading && (rendericon ? rendericon : <Save />)}

        {title ? title : "Add Title"}
      </Button>
    </div>
  );
}

export default ZButton;

const styles = {
  margin: {
    mx: '10px'
  }
}
