import * as React from "react";
import { Input, ViewSave } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import { css as customCss } from '../../content/Style'
import Details from "./Details";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useNavigate } from "react-router-dom";
import Info from "../regular/Info";
import HBox from "../extra/HBox";


export default function TimeCam(props) {
    let navigate = useNavigate();
    const [more, setMore] = React.useState({});
    const [company, setCompany] = React.useState();
    const [project, setProject] = React.useState();
    let cmpRef = React.createRef()
    let prjRef = React.createRef()
    const [path, setPath] = React.useState();
    let latestState = useSelector((state) => state.latest?.data)
    let cameraState = useSelector((state) => state.timecam?.data)
    let companyState = useSelector((state) => state.company?.data)
    let projectState = useSelector((state) => state.project?.data)
    const [filteredProject, setFilteredProject] = React.useState(projectState.results)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.TIMECAM.GET.ACTION, payload: 0 })
        dispatch({ type: REQUESTS.COMPANY.GET.ACTION, payload: 0 })
        dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: 0 })
        console.log("COMPANY DATA:", companyState)
        console.log("PROJECT DATA:", projectState)
    }, [])
    const filterProject = (e) => {
        let company = companyState.results.filter((company) => String(company.owner) === String(e.target.value))
        setFilteredProject(projectState.results.filter((project) => {
            return String(project.company) === String(company[0]?.id)
        }))
    }
    // const optimize_folder = (folder_name, max_length) => {
    //     folder_name = folder_name.toLowerCase().replace(/\s+/g, ' ').replaceAll(" ", "_")
    //     return folder_name.substring(0, max_length);
    // }
    const onSubmit = (e, data) => {
        if (data.image && data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.TIMECAM.PATCH.ACTION : REQUESTS.TIMECAM.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.TIMECAM.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.TIMECAM.GET.ACTION, payload: 0 })
            }
        })
    }
    const fetchPath = (company, project) => {
        console.log(cmpRef.current.value)
        console.log(prjRef.current.value)
        console.log(company, " --", project)
        if (company && project) {
            dispatch({
                type: REQUESTS.FILELIST.SEARCH.ACTION, payload: `files/${company}/${project}/0/`, callback: (data) => {
                    console.log(data)
                    let cam = data.files.length + 1
                    setPath({ dir_path: data.root + "/" + cam, store_path: data.root + "/" + cam })
                }
            })
        } else {
            setPath({ dir_path: "", store_path: "" })
        }
    }
    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={cameraState?.results}
                    onSubmit={onSubmit}
                    onClickFilter={(e,data)=>console.log(data)}
                    headerList={["image","label"]}
                    onDelete={onDelete}
                    title={"Time Camera"}
                    icon="image"
                    showDwnload={true}
                    showUpload={true}
                    moreIcon={[
                        <VisibilityOutlinedIcon sx={{ fontSize: 14 }} />,
                        <MoreHorizOutlinedIcon sx={{ fontSize: 14 }} />,
                    ]}
                    onMore={(data, i) => {
                        if (i === 0) {
                            dispatch({
                                type: REQUESTS.LATESTFILE.GET.ACTION, payload: data.id, callback: (resp) => {
                                    navigate("/imagepreview/", {
                                        state: { data: data, files: resp, back: "/timecam/" }
                                    })
                                }
                            })
                        }
                        if (i === 1) {
                            setMore(data)
                        }
                    }}
                    initData={path}
                    renderCol={(data, key) => {
                        if (key !== 'label') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key3={"long_name"} key4={"description"} />
                    }}
                >
                    <p> </p>
                    <Input type={"select"} label={"Company"} name={"owner"} required={true} onInput={(e) => { filterProject(e) }}
                        options={companyState.results} text={"company_name"} val={"owner"} onChange={
                            (e) => {
                                if (e.target.value) {
                                    let company = companyState.results.filter((company) => String(company.owner) === String(e.target.value))
                                    setFilteredProject(projectState.results.filter((project) => {
                                        return String(project.company) === String(company[0]?.id)
                                    }))
                                    setCompany(company[0].id)
                                    setPath({ dir_path: "", store_path: "" })
                                }
                            }}
                        ref={cmpRef}
                    />
                    <Input type={"select"} label={"Project"} name={"project"} required={true}
                        options={filteredProject} text={"project_name"} val={"id"} onChange={(e) => {
                            setProject(e.target.value)
                        }}
                        ref={prjRef}
                    />
                    <Input maxLength={100} label={"Label"} name={"label"} required={true} />
                    <Input type={"image"} label={"Camera Image"} name={"image"} required={false} />
                    <Button size="small"
                        onClick={() => { fetchPath(company, project) }}
                        sx={{ textTransform: 'capitalize', mb: 1 }}
                    >Get new path</Button>
                    <Input label={"Store Path"} name={"store_path"} required={false} />
                    <Input maxLength={100} label={"DIR Path"} name={"dir_path"} required={true} />
                    <Input maxLength={200} label={"Description"} name={"description"} required={false} />
                    <Input maxLength={15} label={"Location"} name={"location"} required={false} />
                    <Input maxLength={15} label={"Image Rentention "} name={"image_rentention"} required={false} />
                    <Input type={"number"} label={"Image Intervals"} name={"image_intervals"} required={false} />
                    <Stack direction="row" spacing={1}>
                        <Input type={"number"} label={"Duration"} name={"duration"} required={true} />
                        <Input type="checkbox" label={"Active"} name={"active"} required={false} />
                    </Stack>
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}