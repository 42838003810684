import React from 'react';
import { css as customCss } from '../../content/Style'
import { Input, ViewSave, XForm } from "../../libs/Libs"
import { Box, Button, Divider, Grid, Badge, Card, CardMedia, CardContent, Chip, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { REQUESTS } from "../../redux/types";
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
const img404 = ""
export default function CamSettings(props) {
    let location = useLocation()
    let data = location.state
    let dispatch = useDispatch()
    let isValid = (data != undefined && Object.keys(data).length > 0);
    let settingsState = useSelector((state) => state.livesettings?.data)
    let typesState = useSelector((state) => state.types?.data)
    React.useEffect(() => {
        dispatch({ type: REQUESTS.CAMLIVESETTINGS.GET.ACTION, payload: data.id })
        dispatch({ type: REQUESTS.ACTIONTYPES.GET.ACTION, payload: 0 })
    }, [])
    const onSubmit = (e, data) => {
        if (data.image && data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.LIVESETTINGS.PATCH.ACTION : REQUESTS.LIVESETTINGS.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onSubmitType = (e, data) => {
        let action = data.id ? REQUESTS.ACTIONTYPES.PATCH.ACTION : REQUESTS.ACTIONTYPES.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.LIVESETTINGS.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.LIVESETTINGS.GET.ACTION, payload: 0 })
            }
        })
    }
    return (
        <>
            <Grid container>
                <Grid item sm={12} md={4} sx={{ p: 1 }}>
                    <ViewSave
                        hideCols={["id", "created_at",
                            "modified_date", "ptz_url",
                            "action_url", "camera_id", "max_value", "min_value",
                            "active", "owner", "camera"
                        ]}
                        customIcon={<SettingsOutlined />}
                        dataList={settingsState?.results}
                        title={`Camera Settings`}
                        onSubmit={onSubmit}
                    >
                        <Input type={"hidden"} name={"camera"} value={data.id} />
                        <Input type={"select"} label={"Action "} name="action"
                            options={typesState?.results} val={"type"} text={"type"}
                        />
                        <Input maxLength={100} label={"Label"} name={"label"} required={true} />
                        <Input maxLength={500} label={"Property (Add $ symbol for value)"} name={"action_url"} required={false} />
                        <Input type={"number"} label={"Min Value"} name={"min_value"} required={false} />
                        <Input type={"number"} label={"Max Value "} name={"max_value"} required={false} />
                    </ViewSave>
                </Grid>
                <Grid item sm={12} md={8} sx={{ p: 1 }}>
                    <Card style={{ "margin": "2px 4px 2px 4px", ...customCss.Card.body }}>
                        <Grid container>
                            <Grid item sx={{ p: 1 }} md={4}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 151 }}
                                    image={data.image}
                                    alt="Live from space album cover"
                                />
                            </Grid>
                            <Grid item sx={{ p: 1 }} md={8}>
                                <h5 style={{ textTransform: "uppercase" }}>{data.label}</h5>
                                <div style={{ ...customCss.Content.medium }}>{data.ip_address}</div>
                                <div style={{ ...customCss.Content.medium }}>{data.description}</div>
                            </Grid>
                        </Grid>
                        <XForm onSubmit={onSubmitType}>
                            <Grid container>
                                <Grid item md={12} sx={{ p: 1 }}>  <hr /></Grid>
                                <Grid item md={8} sx={{ p: 1 }}>
                                    <Input maxLength={100} label={"Type"} name={"type"} required={true} />
                                </Grid>
                                <Grid item md={4} sx={{ p: 1 }}>
                                    <Button type="submit">Submit</Button>
                                </Grid>
                            </Grid>
                        </XForm>
                        <hr />
                        <Grid container>
                            <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <XForm onSubmit={onSubmit}>
                                    <Input type={"hidden"} name={"camera"} value={data.id} />
                                    <Input type={"select"} label={"Action "} name="action"
                                        options={typesState?.results} val={"type"} text={"type"}
                                    />
                                    <Input maxLength={100} label={"Label"} name={"label"} required={true} />
                                    <Input maxLength={500} label={"Property Please add $ symbol for value"} name={"action_url"} required={false} />
                                    <Input type={"number"} label={"Min Value"} name={"min_value"} required={false} />
                                    <Input type={"number"} label={"Max Value "} name={"max_value"} required={false} />
                                    <Button type="submit">Submit</Button>
                                </XForm>

                            </Grid>
                            <Grid item md={8} xs={12} sx={{ p: 1 }}>
                                {settingsState?.results?.map((action) => {
                                    return <XForm data={action} onSubmit={onSubmit}>
                                        <Grid container>
                                            <Grid item md={3} sx={{ p: 1 }}>
                                                <Input type={"hidden"} name={"camera"} value={data.id} />
                                                <Input type={"hidden"} name={"id"} value={action.id} />
                                                <Input type={"select"} label={"Action "} name="action"
                                                    options={typesState?.results} val={"type"} text={"type"}
                                                />
                                            </Grid>
                                            <Grid item md={3} sx={{ p: 1 }}>
                                                <Input maxLength={100} label={"Label"} name={"label"} required={true} />
                                            </Grid>
                                            <Grid item md={4} sx={{ p: 1 }}>
                                                <Input maxLength={500} label={"Property "} name={"action_url"} required={false} />
                                            </Grid>
                                            <Grid item md={2} sx={{ p: 1 }}>
                                                <Button type="submit">Submit</Button>
                                            </Grid>
                                        </Grid>
                                    </XForm>
                                })}
                            </Grid>
                        </Grid>
                    </Card>
                    {(!isValid) && <div style={{ ...customCss.Content.small, textAlign: 'center' }} className={'d-flex align-items-center justify-content-center h-100'}>
                        <p className='text-center'>please select any company hades<br />
                            you are able to view all details of company</p>
                    </div>}

                </Grid>
            </Grid>
        </>
    )
}