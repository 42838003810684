import * as React from "react";
import { TextField, Button, Grid, Paper, Avatar } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { XForm, ZButton, CImg } from "../../libs/Libs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";
import * as type from "../../redux/types";
import { useEffect } from "react";
import { Camera, Edit, Save } from "@mui/icons-material";
import { Input } from "../../libs/Libs";

const Profile = ({ title, submitLabel }) => {
  title = title ? title : "Sign In";
  submitLabel = submitLabel ? submitLabel : "Sign In";
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let userState = useSelector((state) => state.auth);
  const { data, loading, error } = userState;
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    title = data?.first_name;
  }, []);

  const paperStyle = {
    padding: 10,
    margin: "20px auto",
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center">
      <Grid item align="center" xs={12} md={4} xl={4}>
      <Paper elevation={10} style={paperStyle}>
        <XForm
          data={data}
          onSubmit={(e, data) => {
            console.log("PROFILE", data);
            dispatch({
              type: type.REQUESTS.USER.PATCH.ACTION, payload: data
            })
          }}
        >

          {loading && <LinearProgress sx={{my:1}} />}
          
          <h4>{data?.first_name}</h4>
          <Input type={"image"} label="avtar" name="avtar" avatar/>
          <Input label="First Name" name="first_name"/>
          <Input label="Last Name" name="last_name"/>
          <Input label="DOB" name="dob"/>
          <Input label="DOJ" name="date_joined"/>

          <ZButton
            fullWidth
            title={isEdit ? 'Edit' : 'Save'}
            loading={loading}
            rendericon={isEdit ? <Edit /> : <Save />}
            handleButtonClick={handleEdit}
            type="submit"
            sx={{ my: '10px' }}
          />
        </XForm>
      </Paper>
    </Grid>
    </Grid>
  );
};
export default Profile;
