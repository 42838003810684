import React, { useRef } from "react";
import ZFiles from "../../libs/ZFiles";
function FileManager() {

  return (
    <>
      <ZFiles />
    </>
  );
}

export default FileManager;