import * as React from "react";
import { Badge, TextField, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card } from "@mui/material";
import { REQUESTS } from "../../redux/types";
import { XAutoComplete, ViewSave } from "../../libs/Libs";
import CropImg from "../../libs/imgcrop/CropImg";
import { css as customCss } from '../../content/Style'
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import GroupIcon from "@mui/icons-material/GroupOutlined";
const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];


export default function Home() {
  let dispatch = useDispatch();
  let userState = useSelector((state) => state.user.data);
  let dashboardState = useSelector((state) => state.dashboard);

  const init = () => {
    dispatch({ type: REQUESTS.USER.GET.ACTION, payload: 0 });
    dispatch({ type: REQUESTS.DASHBOARD.GET.ACTION, payload: 0 });
    console.log("USER SATE", userState);
    // setPaginate({ count: userState.data.count, next: userState.data.next, previous: userState.data.previous })
  };
  React.useEffect(() => { init() }, [])

  const onSubmit = (e, data, setLoading, setMessage) => {
    setLoading(true);
    let action = data["id"] > 0 ? REQUESTS.USER.PUT.ACTION : REQUESTS.USER.POST.ACTION
    dispatch({
      type: action,
      payload: data,
    });
    setLoading(false);
  };

  const onClickPagination = (num) => {
    dispatch({
      type: REQUESTS.USER.GET.ACTION,
      payload: `user/0/?page=${num + 1}`,
    });
  }
  

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {dashboardState?.data?.results?.map((data, i) => {
          let cs = { ...customCss.Round }
          cs.color = customCss.Colors[parseInt(i % customCss.Colors.length)]?.color
          cs.backgroundColor = customCss.Colors[parseInt(i % customCss.Colors.length)]?.bg
          return <Grid item xs={6} md={3}>
            <Card display={'flex'} alignContent={'center'} alignItems={'center'} sx={{ ...customCss.Card.body, p: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Badge sx={cs}>
                  <GroupIcon />
                </Badge>
                <Typography sx={{ fontSize: 12 }}>{data.name}</Typography>

              </div>
              <div style={{textAlign:'right'}}><Typography component={'h1'}
               sx={{ fontSize: 14,background:customCss.Colors[1].bg,display:'inline-block',p:.2,px:3,borderRadius:100}}>{data.count}</Typography></div>
              <div></div>
            </Card>
          </Grid>
        })}
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ ...customCss.Card.body, p: 1 }}>
            <BarChart
              series={[
                { data: dashboardState?.data?.results?.map((data, i) => data.count) || [0], label: 'count', id: 'pvId' },
              ]}
              xAxis={[{ data: dashboardState?.data?.results?.map((data, i) => data.name) || [""], scaleType: 'band' }]}
              height={250}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ ...customCss.Card.body, p: 1 }}>
            <BarChart

              series={[
                { data: pData, label: 'pv', id: 'pvId' },
                { data: uData, label: 'uv', id: 'uvId' },
              ]}
              xAxis={[{ data: xLabels, scaleType: 'band' }]}
              height={250}
            />
          </Card>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} sx={{ mb: 2 }}>

        <Grid item xs={12} md={12}>
          <PieChart
            series={[
              {
                data: dashboardState?.data?.results?.map((data, i) => { return { id: i, value: data.count, label: data.name } })||[]
              },
            ]}
            height={200}
          />
        </Grid>
      </Grid> */}
    </>
  );
}
