import SpaceDashboardSharpIcon from "@mui/icons-material/SpaceDashboardOutlined";
import LineStyleSharpIcon from "@mui/icons-material/LineStyleSharp";


import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import Group from '../pages/adminstration/group'
import UserPermission from '../pages/adminstration/user_permission'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import Documents from "../pages/docs/Document";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import VideocamIcon from '@mui/icons-material/VideocamOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';

import Dashboard from "../pages/dashboard/Home";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Users from "../pages/users/Users";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Profile from "../pages/profile/Profile";
import Company from "../pages/company/Company";
import Project from "../pages/project/Project";
import LiveCam from "../pages/livecam/LiveCam";
import TimeCam from "../pages/timecam/TimeCam";
import LivePreview from "../pages/livecam/LivePreview";
import CamSettings from "../pages/livecam/CamSettings";
import ImageViewer from "../pages/image/ImageViewer";
import ImagePreview from "../pages/timecam/ImagePreview";
import Package from "../pages/package/Package";
import Requests from "../pages/package/Requests";
import Team from "../pages/users/Team";
import UserCam from "../pages/company/UserCam";

import IP from "../pages/auth/index/IndexPage"; 
import ContactUs from '../pages/auth/ContactUs'
import FileManager from "../pages/csv/FileManager";

export const HomePage = Dashboard; //its shows as loagout page
export const IndexPage = IP; //its shows as loagout page

export const anonyMenuItems = [
  {
    name: "Index",
    exact: true,
    to: "/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <IP/>,
    // noMenu: true,
  },
  {
    name: "Login",
    exact: true,
    to: "/login/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Login hasForgot={"true"} />,
    // noMenu: true,
  },

  {
    name: "Register",
    exact: true,
    to: "/register/",
    icon: <PersonAddAlt1Icon />,
    component: <Register />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/reset/",
    icon: <PersonAddAlt1Icon />,
    component: <ForgotPassword title={"Reset Password"} submitLabel={"Reset"} />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Reset Password"} submitLabel={"Reset"} />,
    noMenu: true
  },
  {
    name: "Contact Us",
    exact: true,
    to: "/contact_us/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ContactUs  title={"Contact Us"} phone={"9656291915"} email="sujithvs.think@gmail.com" address={"xyz"}/>,
    noMenu: true
  },
  {
    name: "Packages",
    exact: true,
    to: "/packages/",
    icon: <ArticleOutlinedIcon />,
    component: <Package />,
    noMenu: true
  },
  {
    name: "File",
    exact: true,
    to: "/file/",
    icon: <ArticleOutlinedIcon />,
    component: <FileManager />,
    noMenu: true
  },
];

export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard/",
    icon: <SpaceDashboardSharpIcon sx={{ strokeWidth: 4 }} />,
    component: <Dashboard />,
  },
  {
    name: "Change Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Change Password"} submitLabel={"Change"} />,
    noMenu: true
  },
  {
    name: "Group",
    exact: true,
    to: "/group/",
    icon: <GroupsIcon />,
    component: <Group />,
  },
  {
    name: "User Permission",
    exact: true,
    to: "/user_permission/",
    icon: <AdminPanelSettingsIcon />,
    component: <UserPermission />,
  },
  {
    name: "Users",
    exact: true,
    to: "/users/",
    icon: <PeopleOutlined />,
    component: <Users />,
  },
  {
    name: "Team",
    exact: true,
    to: "/team/",
    icon: <PeopleOutlined />,
    component: <Team />,
    noMenu: true
  },
  {
    name: "User Cam",
    exact: true,
    to: "/usercam/",
    icon: <PeopleOutlined />,
    component: <UserCam/>,
    noMenu: true
  },
  {
    name: "Profile",
    exact: true,
    to: "/profile/",
    icon: <AccountBalanceIcon />,
    component: <Profile title={"Sujith VS"} />,
    noMenu: true
  },
  {
    name: "Live Preview",
    exact: true,
    to: "/livepreview/",
    icon: <AccountBalanceIcon />,
    component: <LivePreview />,
    noMenu: true
  },
  {
    name: "Cam Settings",
    exact: true,
    to: "/camsettings/",
    icon: <AccountBalanceIcon />,
    component: <CamSettings />,
    noMenu: true
  },
  {
    name: "Image Viewer",
    exact: true,
    to: "/imageviewer/",
    icon: <AccountBalanceIcon />,
    component: <ImageViewer />,
    noMenu: true
  },
  {
    name: "Company",
    exact: true,
    to: "/company/",
    icon: <AccountBalanceIcon />,
    component: <Company />,

  },
  {
    name: "Project",
    exact: true,
    to: "/project/",
    icon: <BookIcon />,
    component: <Project />,
  },
  {
    name: "Live Camera",
    exact: true,
    to: "/livecam/",
    icon: <VideocamIcon />,
    component: <LiveCam />,
  },

  {
    name: "Timelapse Camera",
    exact: true,
    to: "/timecam/",
    icon: <PhotoCameraIcon />,
    component: <TimeCam />,
  },
  {
    name: "Timelapse Camera",
    exact: true,
    to: "/imagepreview/",
    icon: <PhotoCameraIcon />,
    component: <ImagePreview />,
    noMenu: true
  },
  {
    name: "Packages",
    exact: true,
    to: "/packages/",
    icon: <ArticleOutlinedIcon />,
    component: <Package />,
  },
  {
    name: "Requets",
    exact: true,
    to: "/packages_requests/",
    icon: <RequestPageOutlinedIcon/>,
    component: <Requests/>,
  },
];
