import * as React from "react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Grid,
  Paper,
  Avatar,
  Typography,
  Link,
  Box,
  Divider
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";
import { css as customCss } from '../../../content/Style'
import Package from "../../package/Package";
import './main.css'
import logo from './logo.png'
const IndexPage = ({ title, hasForgot, hasRegister, submitLabel }) => {
  title = title ? title : "Sign In";
  submitLabel = submitLabel ? submitLabel : "Sign In";
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const paperStyle = {
    padding: 20,
  };
  const avatarStyle = { backgroundColor: "#1095bbfc" };
  const btnStyle = { margin: "8px 0px" };
  return (<>
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >

      <Grid className="landing" item align="center" alignItems="center" xs={12} md={12} xl={12}>

        <header>
          <nav class="desktop">
            <Box display="flex"
              justifyContent="space-between"
              alignItems="center" sx={{ width: '100%' }}>
              <Box><h2 class="logo"><img src={logo} height={30}/></h2></Box>
              <Box display="flex"
                justifyContent="center"
                alignItems="center" sx={{ color: customCss.Colors[4].color }}>
                <Typography onClick={() => {
                  navigate("/contact_us/")
                }}>Contact</Typography>
                <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
                <Typography onClick={() => {
                  navigate("/packages/")
                }}>Get a quote</Typography>
                <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
                <Typography onClick={() => {
                  navigate("/login/")
                }}>Login</Typography>
              </Box>
            </Box>

          </nav>
        </header>
        <section class="showcase">
          <video
            src="https://assets.mixkit.co/videos/preview/mixkit-tropical-island-landscape-view-4692-large.mp4"
            muted
            loop
            autoplay
          ></video>
          <Grid container sx={{ zIndex: 9 }}
          >
            <Grid xs={12} md={6} xl={5}>
              <div class="text">
                <h2>Never Stop</h2>
                <h3>Exploring The World</h3>
                <p>
                  BGLIVE Webcam provides anytime access to live conditions of your worksite,
                  perfect for broad site overviews of current conditions and construction activity.
                  Implementing high-quality, maintenance-free cameras.
                  Our variety of web cams can be customized to any project,
                  from single point-of-interest solutions to complete 360-degree perspectives of the entire worksite.
                </p>
                <Button>Explore</Button>
              </div>
            </Grid>
            <Grid xs={12} md={6} xl={5}>

            </Grid>
          </Grid>

          <div class="overlay"></div>
          <ul class="social">
            <li>
              <a href="#"><img src="https://i.ibb.co/x7P24fL/facebook.png" /></a>
            </li>
            <li>
              <a href="#"><img src="https://i.ibb.co/Wnxq2Nq/twitter.png" /></a>
            </li>
            <li>
              <a href="#"><img src="https://i.ibb.co/ySwtH4B/instagram.png" /></a>
            </li>
            <li style={{ color: customCss.Colors[5].color }}>
              © 2024 All rights reserved (V2)
            </li>
          </ul>
        </section>



      </Grid>
    </Grid>

  </>
  );
};
export default IndexPage;
