import * as React from 'react';

export default function XForm({ data, onSubmit, children }) {
  onSubmit = onSubmit ? onSubmit : () => { }
  const ref = React.createRef()
  const [id, setId] = React.useState()
  const [validated, setValidated] = React.useState(false);

  React.useEffect(() => {
    setId('')
    refreshForm(data || {})
  }, [data])

  React.useEffect(() => {
    for (let i in ref.current.elements) {
      validate(ref.current.elements[i])
    }
  }, [])

  const add_remove_css = (error, o, isError) => {
    if (isError) {
      error?.classList?.remove("success")
      error?.classList?.add("error")
      o?.classList?.add("inputerror")
      o?.classList?.remove("inputsuccess")
    } else {
      error?.classList?.remove("error")
      error?.classList?.add("success")
      o?.classList?.remove("inputerror")
      o?.classList?.add("inputsuccess")
    }
  }

  const isEmpty = (value) => {
    return value.length < 2
  }
  const isText = (o) => {
    var regex = /^[a-zA-Z ]+$/;
    let error = o.nextElementSibling;
    error = error ? error : {}
    if (o.value.length >= 1 && regex.test(o.value) !== true && o.required) {
      error.textContent = o.getAttribute("message") ? o.getAttribute("message") : o.getAttribute("label") + " text only field."
      add_remove_css(error, o, true)
      return false
    } else {
      error.textContent = ""
      add_remove_css(error, o, false)
      return true
    }
  }

  const isNumber = (o) => {
    var regex = /^[0-9]+$/;
    let error = o.nextElementSibling;
    if (o.value.length >= 1 && regex.test(o.value) !== true && o.required) {
      error.textContent = o.getAttribute("message") ? o.getAttribute("message") : o.getAttribute("label") + " number only field."
      add_remove_css(error, o, true)
      return false
    } else {
      error.textContent = ""
      add_remove_css(error, o, false)
      return true
    }
  }
  const isValidEmail = (o) => {
    var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let error = o.nextElementSibling;
    if (regex.test(o.value) !== true && o.required) {
      error.textContent = o.getAttribute("message") ? o.getAttribute("message") : "Not a valid email"
      add_remove_css(error, o, true)
      return false
    } else {
      error.textContent = ""
      add_remove_css(error, o, false)
      return true
    }
  }
  const isValidPhone = (value) => {
    return value.length < 2
  }

  const validate = (element, e) => {
    const errors = []
    if (e && e.tagName == "form") {
      if (element.name && element.name.length > 2 && element.required) {
        if (element.type === 'text' && !isText(element)) {
          errors.push(false)
          e.preventDefault()
        }
      }
    } else {
      if (element.name && element.name.length > 2 && element.required) {
        if (element.type === 'textonly') {
          element.addEventListener("input", (e) => {
            if (!isText(element)) {
              e.preventDefault()
            }
          })
        }
        if (element.type === 'email') {
          element.addEventListener("input", (e) => {
            if (!isValidEmail(element)) {
              e.preventDefault()
            }
          })
        }
        if (element.type === 'select-one') {
          element.addEventListener("input", (e) => {
            if (!isText(element)) {
              e.preventDefault()
            }
          })
        }
        if (element.type === 'isnumber') {
          element.addEventListener("input", (e) => {
            if (!isNumber(element)) {
              e.preventDefault()
            }
          })
        }
      }

    }

  }

  const findChild = (children) => {
    return React.Children.map(
      children,
      (child, index) => {
        if (child.props?.name) {
          console.log(child.props?.name)
          children[index] = React.cloneElement(
            child,
            {
              defaultValue: data ? data[child.props?.name] : null,
              ...child.props,
            }
          );
        }
        findChild(child?.props?.children)
      })
  }

  const fillData = (data) => {
    // return findChild(children)
    return React.Children.map(
      children,
      (child) => {
        if (child.props?.name) {
          return React.cloneElement(
            child,
            {
              defaultValue: data ? data[child.props?.name] : null,
              ...child.props,
            }
          );
        }
        return child
      }
    )
  }
  // const calculation = React.useMemo(() => fillData(data), [data]);
  const refreshForm = (data) => {
    // console.log("Form Data", JSON.stringify(data))


    if (data && data.length !== 0) {
      let form = ref.current
      for (let i in form.elements) {
        if (form.elements[i].name !== undefined && data[form.elements[i].name] && form.elements[i].value !== undefined && form.elements[i].name !== "") {
          if (form.elements[i].type === "file") {
          } else if (form.elements[i].type === "checkbox" || form.elements[i].type === "radio") {
            let node = form.elements[i]
            node.checked = data[form.elements[i].name];
          } else {
            try {
              let node = form.elements[i]
              const descriptor = Object.getOwnPropertyDescriptor(node, 'value');
              if (descriptor && descriptor.configurable) {
                delete node.value;
              }
              node.value = data[form.elements[i].name];
              let event = new Event('input', { 'bubbles': true })
              node.dispatchEvent(event);
              if (descriptor) {
                Object.defineProperty(node, 'value', descriptor);
              }
              event = new Event('click', { 'bubbles': true })
              node.dispatchEvent(event);
            } catch (err) { }
          }
        }
      }
      setId(data["id"]);
    } else {
      setId('');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    let data = {}
    let formData = new FormData()
    for (let i in form.elements) {

      if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
        if (form.elements[i].type === "file") {
          if (form.elements[i].files.length !== 0) {
            formData.append(form.elements[i].name, form.elements[i].files[0]);
          }
        } else if (form.elements[i].type === "checkbox" || form.elements[i].type === "radio") {
          let node = form.elements[i]
          data[form.elements[i].name] = node.checked
        } else {
          data[form.elements[i].name] = form.elements[i].value
        }
        if (form.elements[i].multiple && form.elements[i].tagName === 'SELECT') {
          data[form.elements[i].name] = Array.from(form.elements[i].selectedOptions, (option) => option.value);
          formData.append(form.elements[i].name, Array.from(form.elements[i].selectedOptions, (option) => option.value));
        }
      }
    }
    if (id) data["id"] = id
    onSubmit(event, data)
  }
  return (

    <form
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      ref={ref}
    >
      {children}
      {/* {fillData(data)} */}
      {/* {calculation} */}
    </form>
  );
}