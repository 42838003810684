// ---  Should  not update this code----
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { createReducer, createSaga } from "./saga/index";
import { REQUESTS } from './types.js';

const rootReducer = combineReducers({ ...createReducer(REQUESTS) })
function* rootSaga() {
    yield all([...createSaga(REQUESTS)]);
}

const sagaMiddleware = createSagaMiddleware();
const store = compose(
    applyMiddleware(sagaMiddleware)
)(createStore)(rootReducer);
sagaMiddleware.run(rootSaga);
export default store;
// ---  Should  not update this code----