import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Container, Grid, } from "@mui/material";
import { Add, Edit, ResetTvRounded } from "@mui/icons-material";
import EasyCrop from "./EasyCrop";
import getCroppedImg, { getOrginalImage } from "./utils";
import ImageIcon from '@mui/icons-material/Image';

function CropImg({ name, icon, type, style, defaultValue, onChange }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCrop, setIsCrop] = useState(false);
  const [rotation, setRotation] = React.useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const uuid = () => parseInt(Date.now() * Math.random()).toString();
  const myuuid = uuid()
  onChange = onChange ? onChange : () => { }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(selectedImage),
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Container sx={{ flexDirection: "column", p: 0, m: 0 }}>
      <Grid item>
        <div  onPaste={(e) => {

          const file = e.clipboardData.files[0];
          if (file) {
            setSelectedImage(file);
            setIsCrop(true)
          }
        }
        }>
          <span>click and ctrl  + v </span>
          <div style={{ textAlign: "center", display: 'flex', justifyContent: 'center'}}
           onClick={(e)=>{e.target.style.border="2px dashed gray"}} onMouseEnter={(e)=>{e.target.click()}}>
            <label for={myuuid} >
              {(!croppedImage && !defaultValue && type !== 'avatar') && <ImageIcon />}
              {type === 'avatar' ? <Avatar src={croppedImage} {...style} /> : <img src={croppedImage ? croppedImage : defaultValue} width={100} />}
              <a style={{ fontSize: 10 }} href={croppedImage ? croppedImage : defaultValue} target="_blank"> Preview </a>
            </label>
          </div>

          <input
            id={myuuid}
            style={{ width: 0, alignSelf: "center", opacity: (icon || type === 'avatar') ? 0 : 1 }}
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setSelectedImage(file);
                setIsCrop(true)
              }
            }}
          />
          <input value={croppedImage} name={name} style={{ display: 'none', position: 'absolute', zIndex: -1 }} onChange={onChange} />
        </div>
      </Grid>
      {isCrop ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <EasyCrop
            open={isCrop}
            handleClose={() => setIsCrop(false)}
            selectedImage={URL.createObjectURL(selectedImage)}
            onCropComplete={onCropComplete}
            handleSave={showCroppedImage}
            handleOriginal={async (original) => {
              let img = await getOrginalImage(URL.createObjectURL(selectedImage))
              setCroppedImage(img)
              setIsCrop(false)
            }}
            onChangeSliderRotation={(e, rotation) => setRotation(rotation)}
            onRotationChange={setRotation}
            rotation={rotation}
          />
        </Grid>
      ) : null}
    </Container>
  );
}

export default CropImg;
