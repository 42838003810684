import React from 'react';
import { css as customCss } from '../../content/Style'
import { Input, ViewSave, XForm } from "../../libs/Libs"
import { Box, Button, Divider, Grid, Badge, Card, CardMedia, CardContent, Chip, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
const img404 = ""
function Details(props) {
    let { data } = props;
    let dispatch = useDispatch()
    const [isLive, setIsLive] = React.useState(false);
    let isValid = (data != undefined && Object.keys(data).length > 0);
    let settingsState = useSelector((state) => state.livesettings?.data)
    React.useEffect(() => {
        dispatch({ type: REQUESTS.LIVESETTINGS.GET.ACTION, payload: 0 })
    }, [])
    const onSubmit = (e, data) => {
        if (data.image && data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.LIVESETTINGS.PATCH.ACTION : REQUESTS.LIVESETTINGS.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.LIVESETTINGS.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.LIVESETTINGS.GET.ACTION, payload: 0 })
            }
        })
    }
    return (
        <>
            {(isValid) &&
                <Card style={{ "margin": "2px 4px 2px 4px", ...customCss.Card.body }}>
                    <Grid container>
                        <Grid item sx={{ p: 1 }} md={4}>
                            <CardMedia
                                component="img"
                                sx={{ width: 151 }}
                                image={data.image}
                                alt="Live from space album cover"
                            />
                        </Grid>
                        <Grid item sx={{ p: 1 }} md={8}>
                            <h5 style={{ textTransform: "uppercase" }}>{data.label}</h5>
                            <div style={{ ...customCss.Content.medium }}>{data.ip_address}</div>
                            <div style={{ ...customCss.Content.medium }}>{data.description}</div>
                        </Grid>
                    </Grid>

                    <XForm data={data} onSubmit={props.onSubmit} >
                        <Grid container>
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Input type={"hidden"} name={"id"} value={data.id} />
                                <Input maxLength={100} label={"Label"} name={"label"} required={true} />
                                <Input maxLength={500} label={"Description "} name={"description"} required={false} />
                                <Input maxLength={100} label={"Location "} name={"location"} required={false} />
                                <Input type={"checkbox"} label={"Is LIve "} onChange={(e) => setIsLive(e.target.checked)} />

                            </Grid>
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Input label={"Url"} name={"ip_url"} />
                                <Input maxLength={30} label={"Duration"} name={"duration"} required={true} />
                                <Input type="checkbox" label={"Active"} name={"active"} required={false} />
                                <Button type="submit">Submit</Button>
                            </Grid>

                        </Grid>
                    </XForm>
                    <CardContent sx={{ flex: '1 0 auto' }} >
                        <hr className={"mt-4"} />
                        <Stack direction="row" spacing={1}>
                            <Chip icon={<Chip label="1" size="small" />} label="With Icon" color="success" variant="outlined" />
                            <Chip icon={<Chip label="12" size="small" />} label="With Icon" color="primary" variant="outlined" />
                            <Chip icon={<Chip label="100" size="small" />} label="With Icon" color="success" variant="outlined" />
                        </Stack>
                    </CardContent>

                </Card>
            }

            {(!isValid) && <div style={{ ...customCss.Content.small, textAlign: 'center' }} className={'d-flex align-items-center justify-content-center h-100'}>

                <Box sx={{ pt: '30vh' }}>
                    <AutoStoriesIcon sx={{ fontSize: 100, color: customCss.Colors[1], opacity: '0.3' }} />
                    <p className='text-center'>please select any camera hades<br />
                        you are able to view all details of company</p>
                </Box>
            </div>}
        </>
    )
}

export default Details