import * as React from "react";
import { Input, ViewSave, checkIsImageURL } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid } from "@mui/material";
import { css as customCss } from '../../content/Style'
import Details from "./Details";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Info from "../regular/Info";
import HBox from "../extra/HBox";


export default function Users(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState({});
    let userState = useSelector((state) => state.user?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.USER.GET.ACTION, payload: 0
        })
    }, [])

    const onSubmit = (e, data) => {
        console.log("data :", data)
        console.log("Image :", checkIsImageURL(data.avatar))
        if (data.avatar.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.avatar
        }

        let action = data.id ? REQUESTS.USER.PUT.ACTION : REQUESTS.USER.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.COMPANY.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }
    // No need to override in normal edit
    const onEdit = (data, showmodel, loader, setFormData) => {
        let user = data.user
        if (user) {
            delete user.id
        }
        let newData = { ...data, ...user }
        setFormData(newData)
        showmodel(true)
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={userState?.results}
                    onSubmit={onSubmit}
                    headerList={["avatar","first_name"]}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    title={"Users"}
                    icon="avatar"
                    moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 14, ...customCss.Btn.Warn }} />}
                    onMore={(data) => {
                        setMore(data)
                    }}
                    renderCol={(data, key) => {
                        if (key !== 'first_name') return typeof data[key] == 'string'&& <Box style={{ width: '100%' }}>{data[key]}</Box>
                        return <HBox data={data} key1={key} key2={"type"} key3={"email"} key4={"whatsapp_number"} />
                    }}
                >
                    <p> </p>
                    <p style={{ fontSize: 10 }}>Creating and managing users with the user roles .</p>
                    <Input type={"image"} label={"Avatar"} name={"avatar"} required={false} />
                    <Input maxLength={100} label={"First Name"} name={"first_name"} required={true} />
                    <Input maxLength={15} label={"Last Name "} name={"last_name"} required={false} />
                    <Input maxLength={15} label={"Phone "} name={"whatsapp_number"} required={false} />
                    <Input maxLength={30} label={"Email (Username and password will send to this )"} name={"email"} required={true} />
                    <Input maxLength={30} label={"Username"} name={"username"} required={true} />
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}