import * as React from "react";
import { Input, ViewSave } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import { css as customCss } from '../../content/Style'
import Details from "./Details";
import { useNavigate } from "react-router-dom";
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Info from "../regular/Info";
import HBox from "../extra/HBox";



export default function LiveCam(props) {
    let navigate = useNavigate();
    const [more, setMore] = React.useState({});
    const [settings, setSettings] = React.useState({});
    let companyState = useSelector((state) => state.livecam?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.LIVECAM.GET.ACTION, payload: 0 })
    }, [])

    const onSubmit = (e, data) => {
        if (data.image && data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.LIVECAM.PATCH.ACTION : REQUESTS.LIVECAM.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.LIVECAM.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.LIVECAM.GET.ACTION, payload: 0 })
            }
        })
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={companyState?.results}
                    onSubmit={onSubmit}
                    headerList={['image','label']}
                    hideMore={["id", "created_at", "modified_date"]}
                    onDelete={onDelete}
                    title={"Live Camera"}
                    icon="image"
                    moreIcon={[
                        <SettingsOutlined sx={{ fontSize: 14 }} />,
                        <VisibilityOutlinedIcon title="Camera Preview" sx={{ fontSize: 14 }} />,
                        <MoreHorizOutlinedIcon title="More details" sx={{ fontSize: 14 }} />,
                    ]}
                    onMore={(data, i) => {
                        if (i == 0) {
                            navigate("/camsettings/", {
                                state: data
                            })
                        }
                        if (i == 1) {
                            navigate("/livepreview/", {
                                state: data
                            })
                        }
                        if (i == 2) {
                            setMore(data)
                        }
                    }}
                    renderCol={(data, key) => {
                        if (key !== 'label') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key3={"long_name"} key4={"description"} />
                    }}
                >
                    <p> </p>
                    <p style={{ fontSize: 10 }}>Creating project and managing camera </p>
                    <Input type={"image"} label={"Camera Image"} name={"image"} required={false} />
                    <Input label={"IP Address"} name={"ip_address"} required={false} />
                    <Input maxLength={100} label={"Url"} name={"ip_url"} required={false} />

                    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                        <Input type={"select"} label={"Protocol"} name={"protocol"}
                            options={[{ "value": "http://", label: "http" },
                            { "value": "https://", label: "https" }]} text="label" val={"value"} />
                        <Input maxLength={100} label={"Port"} name={"http_port"} required={false} />
                        <Input maxLength={100} label={"Port (https)"} name={"https_port"} required={false} />
                    </Stack>
                    <Input maxLength={100} label={"Label"} name={"label"} required={true} />
                    <Input maxLength={200} label={"Description "} name={"description"} required={false} />
                    <Input maxLength={15} label={"Location "} name={"location"} required={false} />
                    <Input maxLength={15} label={"video Rentention "} name={"video_rentention"} required={false} />
                    <Stack direction="row" spacing={1}>
                        <Input type={"number"} label={"Duration"} name={"duration"} required={true} />
                        <Input type="checkbox" label={"Active"} name={"active"} required={false} />
                    </Stack>
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}