import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Box } from "@mui/material";

function SubMenus({ name, icon, children }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(null);
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
            setAnchorEl(null);
    }

    return (
        <div>
            <div
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
                style={{ height: 40, position: 'relative' }}
            >
                {name ? name : icon}
            </div>
            <Box>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                >
                    {children}
                </Menu>
            </Box>
        </div>
    );
}

export default SubMenus;