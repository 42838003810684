import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, Badge, Button, Grid, Box, Divider, Stack } from '@mui/material';
import { REQUESTS } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { getOrientation } from 'get-orientation/browser';
import getCroppedImg, { getOrginalImage } from "../../libs/imgcrop/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { css as customCss } from '../../content/Style'
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import FormatPaintOutlinedIcon from '@mui/icons-material/FormatPaintOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import ImageViewer from '../image/ImageViewer'
import SubMenus from '../image/SubMenus';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import './main.css'
import { CallToActionSharp } from '@mui/icons-material';
import { Input } from '../../libs/Libs';
import { CalendarIcon } from '@mui/x-date-pickers';
import DatePicker from './DatePicker';
import ReactCompareImage from 'react-compare-image';

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const ImagePreview = (props) => {
    let latestState = useSelector((state) => state.latest?.data)
    let dispatch = useDispatch()
    let location = useLocation()
    let navigate = useNavigate();
    const aspect = 4 / 3
    const [imageSrc, setImageSrc] = React.useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [height, setHeight] = useState(0)
    const [image, setImage] = useState(null)
    const [image1, setImage1] = useState(null)
    const [spliter, setSplitter] = useState(false)
    let { data, back, files } = location.state
    const [selDate, setSelDate] = useState()
    let cropperRef = React.createRef()
    const [dates, setDates] = useState(files.dates)
    const [times, setTimes] = useState(files.images)
    const [times1, setTimes1] = useState([])

    const getCalender = (action, path) => {
        let url = `files/0/0/${encodeURIComponent(data.dir_path + `/${path}`)}/`
        setSelDate(path)
        dispatch({
            type: REQUESTS.FILELIST.SEARCH.ACTION, payload: url, callback: (resp) => {
                if (action === "times1") {
                    setTimes1(resp.files)
                } else {
                    setTimes(resp.files)
                }
            }
        })
    }

    React.useEffect(() => {
        if (dates && dates.length > 0) {
            setSelDate(dates[0])
        }
        if ((dates && dates.length > 0) && (times && times.length > 0)) {
            setImage(`${files.root}/${dates[0]}/${times[0]}`)
            setImage1(`${files.root}/${dates[0]}/${times[0]}`)
            if (times && times.length === 2) {
                setImage1(`${files.root}/${dates[0]}/${times[1]}`)
            }
        }
    }, [])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            cropperRef.current.cropper.getCroppedCanvas({
                fillColor: '#fff',
                imageSmoothingEnabled: false,
                imageSmoothingQuality: 'high',
            }).toBlob((blob) => {
                console.error(blob)
                setCroppedImage(blob);

            })

        } catch (e) {
            console.error(e);
        }

    }, [imageSrc, croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, [])
    const onMediaLoad = (mediaSize) => {
        setHeight(mediaSize.height - 10);
    };
    return (<>
        <div>
            <div className={"controls"}>
                <Grid container>
                    <Grid sm="12" md="12">
                        <Box sx={customCss.MenuBar}>
                            <ArrowBackOutlinedIcon onClick={() => {
                                navigate(back)
                            }} />
                            <Divider orientation="vertical" flexItem />
                            <SubMenus icon={<CalendarIcon />}>
                                <DatePicker selectedDate={selDate} dates={dates} times={times} onChange={(element, value) => {
                                    if (element == "times") {
                                        setImage(`${files.root}/${selDate}/${value}`)
                                    } else if (element == "dates") {
                                        getCalender("times", value)
                                    }
                                }} />
                            </SubMenus>
                            <Divider orientation="vertical" flexItem />
                            <PhotoCameraIcon onClick={() => {
                                navigate("/imageviewer/", {
                                    state: { data: location.state, image: image, back: "/imagepreview/" }
                                })
                            }} />
                            <Divider orientation="vertical" flexItem />
                            <FlipOutlinedIcon sx={{ color: spliter ? "red" : null }} onClick={() => { setSplitter(!spliter); setTimes1([]) }} />
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ width: '100%' }}></Box>
                            {spliter && <><Divider orientation="vertical" flexItem />
                            <SubMenus icon={<CalendarIcon />}>
                                <DatePicker dates={dates} times={times1} onChange={(element, value, selDate) => {
                                    if (element == "times") {
                                        console.log(`${files.root}/${selDate}/${value}`)
                                        setImage1(`${files.root}/${selDate}/${value}`)
                                    }
                                    else if (element == "dates") {
                                        getCalender("times1", value)
                                    }
                                }} />
                            </SubMenus></>}
                            <Divider orientation="vertical" flexItem />
                            <div style={{ marginLeft: 'auto', paddingRight: 12 }}>
                                {data.label}
                            </div>
                        </Box>

                    </Grid>
                </Grid>
            </div>
            <Box height={8}></Box>
            {spliter ? <ReactCompareImage leftImage={image} rightImage={image1} /> :
                <Box className={'cambg'} position={'relative'} zIndex={10} height={'78vh'}>
                    <div className={'crop-container'}>
                        <Cropper
                            image={image}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropShape={"rectangle"}
                            maxZoom={8}
                        />
                    </div>

                </Box>}</div>

    </>
    )
}
export default ImagePreview
