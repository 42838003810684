import * as React from "react";
import { Input, ViewSave, checkIsImageURL } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid } from "@mui/material";
import { css as customCss } from '../../content/Style'
import Details from "./Details";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Info from "../regular/Info";
import { useNavigate, useLocation } from "react-router-dom";
import { ConstructionTwoTone } from "@mui/icons-material";


export default function Team(props) {
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let { data, back, title } = location.state
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState({});
    let userState = useSelector((state) => state.user?.data)
    const [users, setUsers] = React.useState(data?.user?.team)
    const reload = () => {
        dispatch({
            type: REQUESTS.USER.GET.ACTION, payload: data.user?.id
        })
        console.log("USER DATA", userState)
        if (Array.isArray(userState?.team)) {
            setUsers([...userState?.team])
        }
    }

    const onSubmit = (e, data) => {
        if (checkIsImageURL(data.avatar)) {
            console.error("Not a base 64")
            delete data.avatar
        }

        let action = data.id ? REQUESTS.USER.PUT.ACTION : REQUESTS.USER.POST.ACTION
        dispatch({
            type: action, payload: data, callback: () => {
                reload()
            }
        })

    }
    const onDelete = (data) => {
        let action = REQUESTS.USER.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.USER.GET.ACTION, payload: 0 })
            }
        })
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={users}
                    onSubmit={onSubmit}
                    hideCols={["id","last_login","is_superuser","is_staff",
                    "is_active","username","password",
                    "avatar","whatsapp_number","dob","gender","type","date_joined",
                    "email","parent","groups","user_permissions",
                    ]}
                    hideMore={["id", "created_at", "modified_date"]}
                    onDelete={onDelete}
                    title={`${title} - User`}
                    icon="avatar"
                    moreIcon={<MoreHorizOutlinedIcon style={{ ...customCss.Btn.Warn }} />}
                    onMore={(data) => {
                        setMore(data)
                    }}
                >
                    <p> </p>
                    <p style={{ fontSize: 10 }}>Creating and managing users with the user roles .</p>
                    <Input value={data.user?.id} name="parent" type="hidden" />
                    <Input type={"image"} label={"Avatar"} name={"avatar"} required={false} />
                    <Input maxLength={100} label={"First Name"} name={"first_name"} required={true} />
                    <Input maxLength={15} label={"Last Name "} name={"last_name"} required={false} />
                    <Input maxLength={15} label={"Phone "} name={"whatsapp_number"} required={false} />
                    <Input maxLength={30} label={"Email (Username and password will send to this )"} name={"email"} required={true} />
                    <Input maxLength={30} label={"Username"} name={"username"} required={true} />
                    <Input type="hidden" name={"password"} value="#123456" />
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}