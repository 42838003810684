import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import { anonyMenuItems, IndexPage } from '../content/menus';
import Login from '../pages/auth/Login';
import Error404 from '../pages/error/Error404';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function AnonyLayout(props) {
  let subMenus = []
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = React.useState(matches);

  return (
    <>
      <Routes>
        <Route exact={"true"} path={"/"} element={<IndexPage />}></Route>
        <Route exact={"true"} path={"/login"} element={<Login />}></Route>
        {
          anonyMenuItems.map((menu, i) => {
            if (menu.subMenus) {
              subMenus = subMenus.concat(menu.subMenus)
            }
            return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
          })
        }
        {
          subMenus.map((menu, i) => {
            return <Route key={i} exact={"true"} path={menu.to} element={menu.component}></Route>
          })
        }
        <Route path={"*"} element={<Error404 />}></Route>
      </Routes>
    </>
  );
}