import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box, Button, Divider
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { css as customCss } from '../content/Style'
import readXlsxFile from 'read-excel-file'
import writeXlsxFile from 'write-excel-file'
import ZModal from "./ZModal";
import ViewSave from "./ViewSave";
import { CheckBox, Padding } from "@mui/icons-material";

export default function ZFiles({
    title,
    children,
    showModal,
    onAction,
    successBtn
}) {
    const [head, setHead] = React.useState([])
    const [hasHead, setHasHead] = React.useState(true)
    const [rows, setRows] = React.useState([])
    const readExcelFile = (e) => {
        readXlsxFile(e.target.files[0]).then((rows) => {
            setRows(rows)
        })
    }
    const exportExcel = async () => {
        let exportHeader = []
        exportHeader.push({ value: "SL NO", fontWeight: 'bold' })
        head.map((value) => {
            exportHeader.push({ value: value, fontWeight: 'bold' })
        })
        exportHeader.push({ value: "Status", fontWeight: 'bold' })
        let hasRow = false
        let rows = [exportHeader]
        // contacts.map((contact, i) => {
        //   hasRow = true
        //   let cols = []
        //   cols.push({ column: 'SL NO.', type: String, value: String(i + 1) })
        //   contact.map((c, j) => {
        //     cols.push({ column: head[j], type: String, value: String(c) })
        //   })
        //   cols.push({ column: 'Status', type: String, value: status[contact[0]] ? "Success" : "Pending" })
        //   rows.push(cols)

        // })
        // console.log(rows)
        let dt = new Date().toLocaleString().replace(',', '')
        await writeXlsxFile(rows, {
            //columns, // (optional) column widths, etc.
            fileName: `message_report_${dt}.xlsx`
        })
    }
    const c = {
        border: '1px solid #d1d1d1',
        padding: '5px'
    }
    const t = {
        border: '1px solid #d1d1d1',
        padding: '5px',
        background: '#034291', color: 'white', fontSize: '12px'
    }
    return (
        <>
            <Box display="flex"
                justifyContent="center"
                alignItems="center" sx={{ color: customCss.Colors[4].color }}>
                <Typography><input style={{ display: 'none' }} id="fu" type="file" onChange={(e) => {
                    readExcelFile(e)
                }} /><label for="fu">Upload</label></Typography>
                <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
                <Typography onClick={async () => {
                    await exportExcel()
                }}>Download</Typography>
                <Typography> <Button onClick={(e) => {
                    setHead(rows.shift())
                }} >Remove First</Button></Typography>
                <Typography> <Button onClick={(e) => {

                }} >Save</Button></Typography>
            </Box>
            <table style={{ borderCollapse: 'collapse', width: '100%', }}>
                {rows?.map((row, i) => <tr style={c}>
                    {row.map((col, j) => i == 0 ? <th style={ t }>{col}</th> : <td style={c}>{col}</td>)}
                </tr>)}
            </table>
        </>
    );
}
