import { Box } from "@mui/material"
import { nextedKey } from "../../libs/Libs"

const HBox = ({ data, key1, key2, key3, key4 }) => {
    return <Box style={{ width: '100%' }}>
        <Box sx={{ fontSize: 10, fontWeight: 600, mb: .5 }}>{key1?nextedKey(data, key1):null} | {key2?nextedKey(data, key2):null}</Box>
        {key3 &&<Box sx={{ fontSize: 10, mb: .2 }}>{nextedKey(data, key3)}</Box>}
        {key4 &&<Box sx={{ fontSize: 10, mb: .2 }}>{nextedKey(data, key4)}</Box>}
    </Box>
}
export default HBox