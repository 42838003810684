
import React, { useState, useCallback, useEffect } from 'react';
import { Slider, Badge, Button, Grid, Box, Divider } from '@mui/material';
import { REQUESTS } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { css as customCss } from '../../content/Style'
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import CropLandscapeOutlinedIcon from '@mui/icons-material/CropLandscapeOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import FormatShapesOutlinedIcon from '@mui/icons-material/FormatShapesOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import LineWeightOutlinedIcon from '@mui/icons-material/LineWeightOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SubMenus from './SubMenus';
import './main.css'
import { useImgMarkup, ImgMarkupCanvas } from 'react-img-markup';
const ImageViewer = (props) => {
    let navigate = useNavigate();
    let location = useLocation()
    let { data, image, back } = location.state
    const [loading, setLoading] = useState(true)
    const [shape, setShape] = useState(<CropLandscapeOutlinedIcon />)
    const [text, setText] = useState(<CropLandscapeOutlinedIcon />)
    const onSave = (uri) => {
        const link = document.createElement('a')
        link.href = uri
        link.download = data.label
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const onImgLoad = () => setLoading(false)
    const markupDefaultValues = {
        color: '#0af',
        fontSize: 16,
        strokeWidth: 4,
        type: 'rect',
        text: 'insert text!',
    }
    const {
        bind,
        activeColor,
        activeStrokeWidth,
        activeType,
        activeFontSize,
        setActiveColor,
        setActiveStrokeWidth,
        setActiveType,
        setActiveFontSize,
        undo,
        save,
        deletePath,
        activePathId,
        imgMarkupModifiers
    } = useImgMarkup({
        onSave,
        onImgLoad,
        defaultValues: markupDefaultValues,
    })

    const request = (r, v) => {

    }
    const moveBack = () => {
        navigate(back, {
            state: data
        })
    }
    return (<>
        {/* <a href='URL_HERE' download></a> */}
        <div className={"controls"}>
            <Grid container>
                <Grid sm="12" md="12">
                    <Box sx={customCss.MenuBar}>
                        <ArrowBackOutlinedIcon onClick={moveBack} />
                        <Divider orientation="vertical" flexItem />
                        <SubMenus icon={shape}>
                            <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                <HorizontalRuleOutlinedIcon onClick={() => { setActiveType("line"); setShape(<HorizontalRuleOutlinedIcon />) }} />
                                <Divider orientation="vertical" flexItem />
                                <CropLandscapeOutlinedIcon onClick={() => { setActiveType("rect"); setShape(<CropLandscapeOutlinedIcon />) }} />
                                <Divider orientation="vertical" flexItem />
                                <CallMadeOutlinedIcon onClick={() => { setActiveType("arrow"); setShape(<CallMadeOutlinedIcon />) }} />
                                <Divider orientation="vertical" flexItem />
                                <Brightness1OutlinedIcon onClick={() => { setActiveType("ellipse"); setShape(<Brightness1OutlinedIcon />) }} />
                                <Divider orientation="vertical" flexItem />
                                <FormatShapesOutlinedIcon onClick={() => { setActiveType("text"); setShape(<FormatShapesOutlinedIcon />) }} />
                            </Box>
                        </SubMenus>
                        <Divider orientation="vertical" flexItem />
                        <SubMenus icon={<FormatColorTextOutlinedIcon />}>
                            <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                <div>
                                    <input type="color" value='#0af' onChange={(e) => { setActiveColor(e.target.value) }} />
                                </div>
                                <div>
                                    <input type="number" defaultValue={16} min="1" max="62" step={1} onChange={(e) => { setActiveFontSize(e.target.value) }} />
                                </div>
                            </Box>
                        </SubMenus>
                        <Divider orientation="vertical" flexItem />
                        <SubMenus icon={<LineWeightOutlinedIcon />}>
                            <Box flex alignItems={'center'} sx={{ px: 2, minWidth: 210 }}>
                                <input type="number" min="1" max="62" step={1}
                                    onChange={(e) => { setActiveStrokeWidth(e.target.value) }} />
                            </Box>
                        </SubMenus>

                        <Divider orientation="vertical" flexItem />
                        <UndoOutlinedIcon onClick={undo} />
                        <Divider orientation="vertical" flexItem />
                        <SaveAltOutlinedIcon onClick={save} />
                        <Divider orientation="vertical" flexItem />
                        <div style={{ marginLeft: 'auto', paddingRight: 12 }}>
                            {data.label}
                        </div>

                    </Box>
                </Grid>
            </Grid>
        </div>
        <Box height={8}></Box>
        <Box id="container" className={'cambg image-holer'} sx={{ position: 'relative' }} height={'80vh'}>
            <ImgMarkupCanvas imgSrc={image} {...bind} imgStyles={{ height: '80vh' }} />
        </Box>
    </>
    )
}
export default ImageViewer
