import React, { useCallback, useState } from "react";
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import Button from "@mui/material/Button";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Avatar } from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { css as customCss } from '../../content/Style'
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import Cropper from "react-easy-crop";
import getCroppedImg, { getOrginalImage } from "../imgcrop/utils";
const uuid = () => parseInt(Date.now() * Math.random()).toString();
const myuuid = uuid()
export default function ImgInput({ handleChange, name, label, avatar, defaultValue }, props) {
    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [isCrop, setIsCrop] = React.useState(false);
    const [circular, setCircular] = React.useState(false);
    const [zoom, setZoom] = React.useState(1);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [rotation, setRotation] = React.useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
    const [croppedImage, setCroppedImage] = React.useState(null);
    handleChange = handleChange ? handleChange : (image) => { }

    const createBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            var base64data = reader.result;
            if (callback) {
                callback(base64data);
            }else{
                console.error ("createBase64 must have a callback function")
            }
        }
    }

    React.useEffect(() => {
        if (selectedImage) {
            setCroppedImage(selectedImage)
        }
    }, [selectedImage])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    const onRotationChange = () => {

    }
    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                selectedImage,
                croppedAreaPixels,
                rotation
            );
            setCroppedImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation]);
    function dragOverHandler(ev) {
        console.log("File(s) in drop zone");

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }
    function dropHandler(ev) {
        console.log("File(s) dropped");

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...ev.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    createBase64(file,(base64)=>setSelectedImage(base64))
                    console.log(`loaded file[${i}].name = ${file.name}`);
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...ev.dataTransfer.files].forEach((file, i) => {
                console.log(`… file[${i}].name = ${file.name}`);
            });
        }
    }
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            createBase64(file,(base64)=>setSelectedImage(base64))
            // setSelectedImage(file);
        }
    }

    return <>
        <Dialog open={open} onClose={() => { setOpen(false) }} fullWidth sx={{ mx: 1 }}>
            <DialogTitle sx={{ p: 0.5, px: 1, ...customCss.Card.header }}
                style={{ display: 'flex', alignItem: "center", justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: 12 }}>Image Update </Typography>
                <CloseOutlinedIcon sx={{ fontSize: 14, color: 'red' }} onClick={() => { setOpen(false) }} />
            </DialogTitle>

            <DialogContent>
                {!isCrop && <div onPaste={(e) => {
                    const file = e.clipboardData.files[0];
                    if (file) { setSelectedImage(URL.createObjectURL(file)); }
                }
                }>
                    <label for={myuuid}>
                        <div onDrop={dropHandler}
                            onDragOver={dragOverHandler}
                            style={{ padding: '20px 5px 20px 5px', padding: 5, border: '2px dashed gray', display: 'flex', alignItem: "center", justifyContent: 'center' }}>
                            <p style={{ fontSize: 12 }}>Drag one or more files to this <i>drop zone</i>.</p>
                            <br /><p style={{ fontSize: 12 }}>Or click here.</p>
                        </div>
                    </label>
                    <input type="file" value="" accept="image/*" id={myuuid} style={{ display: 'none' }} onChange={onChangeFile} />
                    <div style={{ marginTop: 2 }}>
                        {croppedImage && <img src={croppedImage} width={'100%'} />}
                        {!croppedImage && <CropOriginalIcon sx={{ fontSize: 32 }} />}
                    </div>
                </div>}
            </DialogContent>
            {isCrop && <DialogContent
                dividers
                sx={{
                    background: "#333",
                    position: "relative",
                    height: 300,
                    width: "auto", p: 1,
                    minWidth: { sm: 360 },
                }}
            >
                {isCrop &&
                    <Cropper
                        image={selectedImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={circular ? 1 : 1}
                        cropShape={circular ? "round" : "rect"}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        onRotationChange={onRotationChange}
                        objectFit="vertical-cover"
                    />
                }
            </DialogContent>}
            <DialogActions style={{ padding: '5px 20px 5px 20px', display: 'flex', alignItem: "center", justifyContent: 'end' }}>

                {!isCrop && <>
                    <UndoOutlinedIcon onClick={async () => {
                        setCrop({ x: 0, y: 0 }); setZoom(1);
                        let img = await getOrginalImage(selectedImage)
                        setCroppedImage(img)
                    }
                    } />
                    <CropIcon onClick={() => { setIsCrop(true) }} />
                    <DoneIcon onClick={() => { showCroppedImage(); setOpen(false) }} />
                </>}
                {isCrop && <>
                    <CloseIcon onClick={() => { setIsCrop(false) }} />
                    <DoneIcon onClick={() => { showCroppedImage(); setIsCrop(false) }} />
                </>}
            </DialogActions>
        </Dialog>

        {avatar ? <Avatar src={defaultValue || croppedImage} sx={{ my: 2, ...props }} onClick={() => { setOpen(true) }} /> :
            <div onClick={() => { setOpen(true) }} className="image-floating-input did-floating-input" style={{ paddingTop: 6, paddingBottom: 6 }}>
                {(defaultValue || croppedImage) ? <img src={defaultValue || croppedImage} height="100%" /> : <CropOriginalIcon sx={{ fontSize: 16 }} />}
                <label className="float-label">{label ? label : name}</label>

            </div>}
        <input defaultValue={croppedImage} value={croppedImage} name={name} style={{ display: 'none', position: 'absolute', zIndex: -1 }} />
    </>
}