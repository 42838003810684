import * as React from "react";
import { Input, ViewSave } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Box, Button, Divider, Grid } from "@mui/material";
import { css as customCss } from '../../content/Style'
import Details from "./Details";
import Info from "../regular/Info";
import { SlUserFollow } from "react-icons/sl";
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useNavigate } from "react-router-dom";
import HBox from "../extra/HBox";


export default function Company(props) {
    let navigate = useNavigate()
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState({});
    const [camera, setCamera] = React.useState({});
    let companyState = useSelector((state) => state.company?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.COMPANY.GET.ACTION, payload: 0
        })
    }, [])

    const onSubmit = (e, data) => {
        console.log("SUBMIT DATA", data)
        if (data.image.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let new_data = {
            company_name: data.company_name,
            image: data.image,
            company_phone: data.company_phone,
            company_email: data.company_email,
            address: data.address,
            city: data.city,
            country: data.country,
            keyperson: data.keyperson,
            keypersonphone: data.keypersonphone,
            fax: data.fax,
            duration: data.duration,
            location: data.location,
            image_retention_max: data.image_retention_max,
            video_retention_max: data.video_retention_max,
            id: data.id
        }

        data.group = "LP"
        delete data.company_name
        delete data.image
        delete data.company_phone
        delete data.company_email
        data.first_name = new_data.company_name
        data.type = "Company"
        if (data.password.length < 3) {
            delete data.password
        }
        new_data["user"] = data

        let action = data.id ? REQUESTS.COMPANY.PATCH.ACTION : REQUESTS.COMPANY.POST.ACTION
        dispatch({ type: action, payload: new_data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.COMPANY.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }
    // No need to override in normal edit
    const onEdit = (data, showmodel, loader, setFormData) => {
        console.log("DATA", data)
        let user = data.user
        if (user) {
            delete user.id
        }
        let newData = { ...data, ...user }
        setFormData(newData)
        showmodel(true)
    }

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={companyState?.results}
                    onSubmit={onSubmit}
                    headerList={['image','company_name']}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    title={"Company"}
                    icon="image"
                    moreIcon={[<GroupAddOutlinedIcon title="Add New User" style={{ fontSize: 15 }} />,
                    // <PhotoCameraFrontIcon style={{ marginLeft: 2, marginRight: 10 }} />,
                    <span style={customCss.Btn.Warn}><MoreHorizOutlinedIcon  style={{ fontSize: 15 }} /></span>
                    ]}
                    onMore={(data, i) => {
                        if (i === 0) {
                            navigate("/team/", {
                                state: { data: data, back: "/company/", title: data.company_name }
                            })
                        }
                        if (i === 1) {
                            navigate("/usercam/", {
                                state: { data: data, back: "/company/", title: data.company_name }
                            })
                        }
                        if (i === 2) {
                            setMore(data)
                        }
                    }}
                    renderCol={(data, key) => {
                        if (key !== 'company_name') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key2={'user.username'} key3={"company_email"} key4={"company_phone"} />
                    }}
                >
                    <p> </p>
                    <p style={{ fontSize: 10 }}>Creating and managing users with the user roles .</p>
                    <Input type={"image"} label={"Company Image"} name={"image"} required={false} />
                    <Input maxLength={100} label={"Company Name"} name={"company_name"} required={true} />
                    <Input maxLength={15} label={"Phone "} name={"company_phone"} required={false} />
                    <Input maxLength={30} label={"Email"} name={"company_email"} required={false} />
                    <Divider textAlign="left" sx={{ fontSize: 10 }}>Login details</Divider>
                    <Input name={"first_name"} type="hidden" defaultValue="---" />
                    <Input name={"last_name"} type="hidden" defaultValue="---" />
                    <Input maxLength={15} label={"Phone "} name={"whatsapp_number"} required={false} />
                    <Input maxLength={30} label={"Email (Username and password will send to this )"} name={"email"} required={true} />
                    <Input maxLength={30} label={"Username"} name={"username"} required={true} />
                    <Input maxLength={30} label={"Password"} name={"password"} required={false} />

                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>
                <Details data={more} onSubmit={onSubmit} />
                <Box>

                </Box>
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}