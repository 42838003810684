import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
  Cancel,
  CropOriginal,
  CircleOutlined,
  RectangleOutlined,
} from "@mui/icons-material";
import Cropper from "react-easy-crop";
import { Typography, Slider, Box, Stack } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EasyCrop = ({
  open,
  handleSave,
  handleOriginal,
  handleClose,
  selectedImage,
  onCropComplete,
  onChangeSliderRotation,
  onRotationChange,
  rotation,
}) => {
  const [circular, setCircular] = React.useState(false);
  const [zoom, setZoom] = React.useState(1);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [isCrop, setIsCrop] = React.useState(false);

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <Box
        sx={{
          flexDirection: "column",
        }}
      >
        <DialogContent
          dividers
          sx={{
            background: "#333",
            position: "relative",
            height: 300,
            width: "auto", p: 1,
            minWidth: { sm: 360 },
          }}
        >
          {isCrop &&
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={circular ? 1 : 1}
              cropShape={circular ? "round" : "rect"}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onRotationChange={onRotationChange}
              objectFit="vertical-cover"
            />
          }
          {!isCrop && <img src={selectedImage} width={'100%'} />}
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", mx: 3, my: 2 }}>
          {isCrop && <> <Stack direction={'row'} width={{ width: '100%' }} alignItems={'center'}>
            <ZoomInIcon sx={{ mx: 2 }} />
            <div style={{ width: 150, paddingRight: '5px' }}>
              <Slider
                valueLabelDisplay="auto"
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div style={{ width: 150 }}>
              <Slider
                valueLabelDisplay="auto"
                min={0}
                max={360}
                step={1}
                value={rotation}
                onChange={onChangeSliderRotation}
              />
            </div>
          </Stack>
            <Box>
              <Typography>Rotation:</Typography>

            </Box>
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <Button
                variant="contained"
                startIcon={<CropOriginal />}
                onClick={handleSave}
                sx={{ bgcolor: "green", marginRight: "10px", color: "#fff" }}
              >
                Crop
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                startIcon={<Cancel />}
                sx={{ bgcolor: "ActiveBorder", color: "#fff" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => setCircular(!circular)}
                startIcon={circular ? <RectangleOutlined /> : <CircleOutlined />}
                sx={{ bgcolor: "darkblue", color: "#fff" }}
              >
                {circular ? "Crop in Square" : "Crop in Circle"}
              </Button>
            </Box></>}
          {!isCrop && <Stack direction={'row'} width={{ width: '100%' }} alignItems={'center'}>

            <CloseIcon onClick={() => { handleClose() }} />
            <CropIcon onClick={() => { setIsCrop(true) }} />
            <DoneIcon onClick={() => { handleOriginal(selectedImage) }} />
          </Stack>}
        </DialogActions >
      </Box >
    </Dialog >
  );
};
export default EasyCrop;
const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
