export const init = { body: { color: '#FFFFFF', background: '#FFFFFF', border: 'none', padding: 0, margin: 0 } }
export const initText = { content: { color: '#FFFFFF', fontSize: 14, fontWeight: 500, text: 'This is sample' } }
export const textOnly = { content: { text: 'This is sample' } }
export const read = () => {
    let ob = init
    if (localStorage.getItem("css")) {
        return JSON.parse(localStorage.getItem("css"))
    }
    return ob
}
export const css = read()
export const save = (css) => {
    localStorage.setItem("css", JSON.stringify(css))
    console.log("saved...")
}
