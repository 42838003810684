import { createAction } from './saga'
export const AUTH_DATA = "auth";

// ---  Your Part Do not update above code----

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ISLOGGED = "ISLOGGED";
export const GROUP = "GROUP";
export const USERGROUP = "USERGROUP";
export const DASHBOARD = "DASHBOARD";
export const USER = "USER";
export const FORGOT = "FORGOT";
export const COMPANY = "COMPANY";
export const PROJECT = "PROJECT";
export const LIVECAM = "LIVECAM";
export const LIVESETTINGS = "LIVESETTINGS";
export const CAMLIVESETTINGS = "CAMLIVESETTINGS";
export const PACKAGE = "PACKAGE";
export const TIMECAM = "TIMECAM";
export const ACTIONTYPES = "ACTIONTYPES";
export const PTZACTION = "PTZACTION";
export const FILELIST = "FILELIST";
export const LATESTFILE = "LATESTFILE";
export const MAIL = "MAIL";
export const REQUESTS = {
    ...createAction(LOGOUT, "", AUTH_DATA),
    ...createAction(ISLOGGED, "", AUTH_DATA),
    ...createAction(LOGIN, "token", AUTH_DATA),
    ...createAction(GROUP, "group", "group",{success:"User group updated",error:"Failed to add group"}),
    ...createAction(USERGROUP, "user/group", "usergroup",{success:"User group changed",error:"Failed to change group"}),
    ...createAction(DASHBOARD, "dashboard", "dashboard"),
    ...createAction(USER, "user", "user",{success:"User updated successfully",error:"Failed to add user"}),
    ...createAction(FORGOT, "forgotpassword", "forgot"),
    ...createAction(COMPANY, "company", "company",{success:"company updated successfully",error:"Failed to add company"}),
    ...createAction(PROJECT, "project", "project",{success:"Project updated successfully",error:"Failed to add Project"}),
    ...createAction(LIVECAM, "livecam", "livecam",{success:"Livecam updated successfully",error:"Failed to add Livecam"}),
    ...createAction(LIVESETTINGS, "livesettings", "livesettings",{success:"Settings updated successfully",error:"Failed to add Settings"}),
    ...createAction(CAMLIVESETTINGS, "livesettings/cam", "livesettingscam",{}),
    ...createAction(TIMECAM, "timelapse", "timecam",{success:"Timelapse updated successfully",error:"Failed to add timelapse"}),
    ...createAction(ACTIONTYPES, "actiontypes", "types",{success:"Types updated successfully",error:"Failed to add type"}),
    ...createAction(PACKAGE, "package", "package",{success:"Package updated successfully",error:"Failed to add package"}),
    ...createAction(PTZACTION, "ptzaction", "ptzaction"),
    ...createAction(FILELIST, "files", "files"),
    ...createAction(LATESTFILE, "latest", "latest"),
    ...createAction(MAIL, "mail", "mail"),
}