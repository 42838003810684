import React from 'react';
import { css as customCss } from '../../content/Style'
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Input, ViewSave, XForm } from "../../libs/Libs"
import { Box, Button, Divider, Grid, Badge, Card, CardMedia, CardContent, Chip, Stack } from "@mui/material";
const img404 = ""
function Details(props) {
    let { data } = props;
    let isValid = (data != undefined && Object.keys(data).length > 0);
    return (
        <>
            {(isValid) &&
                <Card style={{ "margin": "2px 4px 2px 4px", ...customCss.Card.body }}>
                    <Grid container>
                        <Grid item sx={{ p: 1 }} md={4}>
                            <CardMedia
                                component="img"
                                sx={{ width: 151 }}
                                image={data.avatar}
                                alt="Live from space album cover"
                            />
                        </Grid>
                        <Grid item sx={{ p: 1 }} md={8}>
                            <h5 style={{ textTransform: "uppercase" }}>{data.first_name}</h5>
                            <div style={{ textTransform: "lowercase", ...customCss.Content.medium }}>{data.email}</div>
                            <div style={{ textTransform: "uppercase", ...customCss.Content.medium }}>{data.phone}</div>
                        </Grid>
                    </Grid>
                    <XForm data={data} onSubmit={props.onSubmit}>
                        <Grid container>
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Input type={"hidden"} name={"id"} value={data.id} />
                                <Input type={"image"} label={"Avatar"} name={"avatar"} required={false} />
                                <Input maxLength={100} label={"First Name"} name={"first_name"} required={true} />
                                <Input maxLength={15} label={"Last Name "} name={"last_name"} required={false} />
                                <Input maxLength={15} label={"Phone "} name={"whatsapp_number"} required={false} />

                            </Grid>
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Input maxLength={30} label={"Email (Username,password send to this )"} name={"email"} required={true} />
                                <Input maxLength={30} label={"Username"} name={"username"} required={true} />
                                <Button type="submit">Submit</Button>
                            </Grid>
                        </Grid>
                    </XForm>
                </Card>
            }

            {(!isValid) && <Box style={{ ...customCss.Content.small, textAlign: 'center' }} className={'d-flex align-items-center justify-content-center'}>

                <Box sx={{ pt: '30vh' }}>
                    <AutoStoriesIcon sx={{ fontSize: 100, color: customCss.Colors[1], opacity: '0.3' }} />
                    <p className='text-center'>please select any user hades<br />
                        you are able to view all details of user</p>
                </Box>
            </Box>}
        </>
    )
}

export default Details