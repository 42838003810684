import * as React from "react";
import { Grid, Avatar, Typography, Box } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { XForm, ZButton, Input } from "../../libs/Libs";
import { REQUESTS } from "../../redux/types";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";
import { css as customCss } from '../../content/Style'

const ForgotPassword = ({ title, changePassword, submitLabel }) => {
    title = title ? title : "Forgot Password";
    submitLabel = submitLabel ? submitLabel : "Sign In";
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    let location = useLocation()
    let { data } = location?.state || {}
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('becon')
    const token = urlParams.get('token')

    const paperStyle = {
        padding: 20,
    };
    const avatarStyle = { backgroundColor: "#1095bbfc" };
    const btnStyle = { margin: "8px 0px" };
    if (!data && !id) return <Box sx={{textAlign:'center', mx:10, my:10,color:'red'}}><h1>Someting went wrong</h1></Box>
    if (!data && id) {data = {id:id};}
    return (
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ 'height': '80vh' }}
        >

            <Grid item align="center" alignItems="center" xs={12} md={3} xl={3}>

                <Box sx={{ ...customCss.Form }}>
                    {loading && <LinearProgress sx={{mb:1}}/>}
                    <Avatar style={avatarStyle}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <h4>{title}</h4>
                    {data && <div style={{ marginBottom: 5 }}>Hi, {data?.first_name}</div>}
                    <XForm
                        onSubmit={(e, data) => {
                            setLoading(true)
                            let url = changePassword ? REQUESTS.USER.PATCH.ACTION : REQUESTS.FORGOT.POST.ACTION
                            dispatch({
                                type: url,
                                payload: data,
                                callback: (resp) => {
                                    setLoading(false)
                                }
                            });

                        }}
                    >
                        {changePassword ? <>
                            <Input
                                type="hidden"
                                name="id"
                                value={data?.id}
                            />
                            <Input
                                type="password"
                                required
                                label="New Password"
                                name="password"
                            />
                            <Input
                                type="password"
                                required
                                label="Confirm Password"
                            />
                        </> : <>
                            <Input
                                fullWidth
                                required
                                label="Email"
                                name="email"
                            />
                        </>
                        }
                        <ZButton
                            fullWidth
                            loading={loading}
                            title={submitLabel}
                            bgcolor={"green"}
                            type={"submit"}
                        />
                        <Typography sx={{ fontSize: 12, p: 2 }}>
                            <span onClick={() => { navigate("/login/") }}>Login</span>
                        </Typography>
                    </XForm>
                </Box>
            </Grid>
        </Grid>
    );
};
export default ForgotPassword;
