import { css as customCss } from '../../content/Style'
export default function Info() {
    return <div style={{ ...customCss?.Content?.small, position: "relative", height: '100%', paddingTop: '2%', width: '100%' }}>
        <h4 style={{ color: customCss?.Colors[0].color }}>Company Policies</h4>
        <div style={{ color: customCss?.Colors[1].color }}>
            <p>site has restricted areas  based on permissions,It will need to allow access to different users based on a specification.</p>
        </div>
        <h4 style={{ color: customCss?.Colors[0].color }}>Terms & conditions</h4>
        <div style={{ color: customCss?.Colors[1].color }}>
            <p>site has restricted areas  based on permissions,It will need to allow access to different users based on a specification.</p>

        </div>
        <h4 style={{ color: customCss?.Colors[0].color }}>Camera Regulations</h4>
        <div style={{ color: customCss?.Colors[1].color }}>
            <p>site has restricted areas  based on permissions,It will need to allow access to different users based on a specification.</p>

        </div>
        <h4 style={{ color: customCss?.Colors[0].color }}>Package Details</h4>
        <div style={{ color: customCss?.Colors[1].color }}>
            <p>site has restricted areas  based on permissions,It will need to allow access to different users based on a specification.</p>

        </div>
    </div>
}