const FILL = "FILL"
const APPEND = "APPEND"
const REPLACE = "REPLACE"
const REMOVE = "REMOVE"
const DO_NOTHING = "DO_NOTHING"
// ---should not  update this section----
const PROJECT_NAME = process.env.REACT_APP_APP_NAME;

export const cachedData = (storeName, rtnType = "array") => {
    let re = rtnType === "array" ? [] : {}
    storeName = PROJECT_NAME + "_" + storeName
    try {
        re = JSON.parse(localStorage.getItem(storeName)) || []
    } catch (e) {

    }
    console.log(storeName, re)
    return re

}

export const updateCacheData = (storeName, prev, data, action, cache) => {
    storeName = PROJECT_NAME + "_" + storeName
    console.log("XXXXXXXXXXX = ",storeName)
    // let prev = localStorage.getItem(storeName) ? JSON.parse(localStorage.getItem(storeName)) : {}
    if (action === APPEND) {
        if (prev?.results) {
            prev.results.unshift(data)
        } else if (Array.isArray(prev)) {
            prev.unshift(data)
        }else{
            prev = data
        }
        console.log("APPEND:", prev, data)
    }
    if (action === REPLACE) {
        if (prev?.results) {
            prev.results = prev.results.filter((d) => {
                return d.id !== data
            })
        } else if (Array.isArray(prev)) {
            console.log("LOG", data)
            prev = prev?.map((d) => {
                return d.id === data.id ? data : d
            })
        }
    }
    if (action === REMOVE) {
        if (prev?.results) {
            prev.results = prev.results.filter((d) => {
                return d.id !== data
            })
        } else {
            prev = prev.filter((d) => {
                return d.id !== data
            })
        }
    }
    if (action === FILL) {
        prev = data
    }
    console.log("Caching",cache,action)
    if (cache){
        localStorage.setItem(storeName, JSON.stringify(prev))
        return JSON.parse(localStorage.getItem(storeName))
    }
    return prev
}

// ---should not  update this section----